import { MemberType } from '../../../../../api/ceremony';
import { Modal } from '../../../../../components/v2/Modal/Modal';
import renderText from '../../../../../helpers/renderText';
import { useDashboard } from '../../../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../../../provider/moduleAccessProvider';
import { usePaywallModal } from '../../../../../provider/paywallModalProvider';
import { ModalRoleItem } from '../../../../EventCreation/ModalRoleItem';

export const ChooseCollaboratorModal = () => {
  const {
    couple1,
    couple2,
    currentUser,
    officiant,
    weddingPlanner,
    chooseCollaboratorModalOpen,
    setChooseCollaboratorModalOpen,
    setMemberType,
    setMemberForEditing,
    setAddModalOpen,
  } = useDashboard();

  const { hasAccessToInviteOfficiant, hasAccessToInviteWeddingPlanner } =
    useAccessibleModule();

  const {
    setIsPaywallModalOpen,
    setPaywallModalType,
    setPaywallModalReferrer,
  } = usePaywallModal();

  return (
    <Modal
      fullWidth
      isOpen={chooseCollaboratorModalOpen}
      onClose={() => setChooseCollaboratorModalOpen(false)}
    >
      <div>
        <ModalRoleItem
          text={renderText('invite_partner')}
          onClick={() => {
            setChooseCollaboratorModalOpen(false);
           
            setMemberType(
              currentUser?.id === couple1?.id
                ? couple2?.member_type ? couple2?.member_type : MemberType.other
                : couple1?.member_type ? couple1?.member_type : MemberType.other
            );
            setMemberForEditing(
              currentUser?.id === couple1?.id ? couple2 : couple1 ? couple1 : undefined
            );
            setAddModalOpen(true);
          }}
          selected={false}
          disabled={
            currentUser?.id === couple1?.id
              ? couple2 && !!couple2.user_id && !!couple2.email
              : couple1 && !!couple1.user_id && !!couple1.email
          }
        />
        <ModalRoleItem
          text={`Invite Officiant`}
          onClick={() => {
            setChooseCollaboratorModalOpen(false);
            // if (hasAccessToInviteOfficiant) {
              setMemberType(MemberType.officiant);
              setMemberForEditing(officiant);
              setAddModalOpen(true);
            // } else {
            //   setIsPaywallModalOpen(true);
            //   setPaywallModalReferrer('officiant');
            //   setPaywallModalType('officiant');
            // }
          }}
          selected={false}
          disabled={officiant && !!officiant.user_id && !!officiant.email}
        />

        <ModalRoleItem
          text={`Invite Planner`}
          onClick={() => {
            setChooseCollaboratorModalOpen(false);
            if (hasAccessToInviteWeddingPlanner) {
              setMemberType(MemberType.weddingPlanner);
              setMemberForEditing(weddingPlanner);
              setAddModalOpen(true);
            } else {
              setIsPaywallModalOpen(true);
              setPaywallModalReferrer('weddingPlanner');
              setPaywallModalType('weddingPlanner');
            }
          }}
          selected={false}
          disabled={
            weddingPlanner && !!weddingPlanner.user_id && !!weddingPlanner.email
          }
        />
      </div>
    </Modal>
  );
};

import { useNavigate } from 'react-router-dom';

import Container from '../../../components/Container';
import Button from '../../../components/v2/Button/Button';
import { Typography } from '../../../components/v2/Typography/Typography';
import paymentFailedPng from '../images/payment-failed.png';
import paymentSuccessPng from '../images/payment-success.png';

type PayConfirmationInnerProps = {
  title: string;
  message?: string;
  buttonText: string;
  buttonLink: string;
  status?: boolean;
  secondaryButtonText: string;
  secondaryButtonLink: string;
};

const PayConfirmationInner = (props: PayConfirmationInnerProps) => {
  const {
    title,
    message,
    buttonText,
    buttonLink,
    status,
    secondaryButtonText,
    secondaryButtonLink,
  } = props;
  const navigate = useNavigate();
  return (
    <>
      <Typography className='mt-8' type='display-50'>
        Provenance
      </Typography>
      <img
        src={status ? paymentSuccessPng : paymentFailedPng}
        className='w-20 h-20 mt-8'
      />
      <Typography type='display-200' className='mt-8'>
        {title}
      </Typography>

      <Container className='content-center place-content-center mt-4 auto-rows-fr'>
        <div className='hidden md:block md:col-span-1 lg:col-span-3'></div>
        <div className='col-span-4 md:col-span-4 lg:col-span-6'>
          <div className=' p-6 flex flex-col items-center text-center'>
            <div className='grid grid-cols-12 gap-x-10'>
              <div className='col-span-1'></div>
              <div className='col-span-10 flex flex-col font-inter text-forest-primary text-center py-2'>
                <div>{message}</div>
              </div>
            </div>
            <div className='flex flex-col md:flex-row justify-center gap-x-4 mt-4'>
              <Button
                variant='secondary'
                onClick={() => {
                  navigate(buttonLink);
                }}
              >
                {buttonText}
              </Button>
              {!status && (
                <Button
                  onClick={() => {
                    navigate(secondaryButtonLink);
                  }}
                >
                  {secondaryButtonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PayConfirmationInner;

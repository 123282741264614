import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/v2/Button/Button';
import { Modal } from '../../components/v2/Modal/Modal';
import { Typography } from '../../components/v2/Typography/Typography';
import { useDashboard } from '../../provider/dashboardProvider';
import { InfoBox } from '../ModulesV2/RingExchange/InfoBox';

import DiplomaPreviewPNG from './images/diploma-preview.png';

const OrdainedSuccessModal = () => {
  const { ceremony, ordainedSuccessModalOpen, setOrdainedSuccessModalOpen } =
    useDashboard();

  const navigate = useNavigate();
  return (
    <Modal
      isOpen={ordainedSuccessModalOpen}
      onClose={() => {
        setOrdainedSuccessModalOpen(false)
        window.location.reload();
      }}
      showCloseButton={true}
      title={''}
    >
      <div className='max-w-[500px] flex flex-col justify-center items-center'>
        <Typography
          type='display-100'
          className='text-center max-w-[436px] mt-6'
        >
          <>
            Congratulations!<br /> You are now a Certified Minister of{' '} <br />
            <b>The Provenance Center</b>
          </>
        </Typography>

        <img
          className='w-[215px] mt-8'
          src={DiplomaPreviewPNG}
          alt='Diploma Preview'
        />

        <Button
          onClick={() => {
            setOrdainedSuccessModalOpen(false);
            if (ceremony?.id) {
              navigate(`/ceremony/${ceremony.id}/ordained`);
            }
          }}
          renderRightIcon={() => (
            <svg
              width='18'
              height='18'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M20 4.8315V15.9697C20 16.4108 19.6422 16.7678 19.2 16.7678C18.7578 16.7678 18.4 16.4108 18.4 15.9697V6.76185L5.36489 19.7656C5.20938 19.9202 5.00438 20 4.79988 20C4.59538 20 4.39048 19.9221 4.23438 19.7662C3.92187 19.4544 3.92187 18.9491 4.23438 18.6379L17.2795 5.62419L7.99941 5.59652C7.5554 5.59466 7.1984 5.23553 7.1999 4.79658C7.20147 4.35544 7.5593 4 7.99991 4L19.2 4.03273C19.645 4.03541 20 4.39256 20 4.8315Z'
                fill='#efede7'
              />
            </svg>
          )}
          className='mt-8'
        >
          Get Your Official Certificate
        </Button>
      </div>
    </Modal>
  );
};

export default OrdainedSuccessModal;

import { MemberType } from '../api/ceremony';
import { useDashboard } from '../provider/dashboardProvider';

import textsObj from './TextsObj';
import { getCookie, setCookie } from './helper';


const renderText = (key: string) => {
    const {currentUser, currentRole, couple1, couple2} = useDashboard()
    let memberType = currentUser?.member_type;

    if (!memberType && currentRole) {
        memberType = currentRole;
    }

    if(memberType){
        setCookie('memberType', memberType);
    }

    if(!memberType){
        memberType = getCookie('memberType') as MemberType;
    }
    
    //if (!memberType) console.log(`WARNING: Unable to get memberType for key: ${key}`);

    const dynamicTextMaster = {
        'couple1Name': couple1?.preferred_name || '',
        'couple2Name': couple2?.preferred_name || '',
    };

    return getTexts(key, memberType, dynamicTextMaster);
};

export const getTexts = (key: string, memberType?: MemberType, dynamicTextMaster?:{[key:string]: string } ) => {
    if (!textsObj[key]) {
        console.log(`WARNING: TextsObj does not have key ${key}`);
        return ''
    }

    if (!memberType) return textsObj[key].default;
    
    let  text = textsObj[key]['memberTypes'][memberType] !== undefined ? textsObj[key]['memberTypes'][memberType] : textsObj[key].default;

    if( text && dynamicTextMaster && textsObj[key]['dynamicText']) {
        for(key in dynamicTextMaster) {
            text = text.replace(`{{${key}}}`, dynamicTextMaster[key])
        }
    }


    return text !== undefined ? text : textsObj[key].default;
};


export default renderText;
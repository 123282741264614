import { Collapse } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router-dom';

import { Ceremony, generalLog, Member } from '../../api/ceremony';
import {
  ChecklistItem,
  ChecklistItemType,
  saveChecklistItem,
} from '../../api/checklistItems';
import {
  changeSpeechOutputCompletionStatus,
  downloadOutput,
  getOutputData,
  outputModules,
  OutputResponse,
  TipsIdeas,
} from '../../api/output';
import { OrderSources, appToolOptions } from '../../api/paywall';
import { QuestionAnswerStatusObj } from '../../api/question';
// import { replacePlaceholders } from '../../helpers/placeholderHelper';
import { generateToastbuilderText } from '../../api/textGenerationAi';
import { RichTextToolbar } from '../../components/RichTextToolbar';
import Button from '../../components/v2/Button/Button';
import { Separator } from '../../components/v2/Separator/Separator';
import { Typography } from '../../components/v2/Typography/Typography';
import { getAPIErrorMessage } from '../../helpers/helper';
import { getTextDurationString } from '../../helpers/textHelper';
import { Module } from '../../hooks/useActiveModule';
import useQuestions from '../../hooks/useQuestions';
import { useNPS } from '../../provider/NPSProvider';
import { useOutput } from '../../provider/outputProvider';
import { useAllQuestions } from '../../provider/questionsProvider';
import { ModuleQuestion } from '../ModulesV2/components';
import { CollapseButton } from '../ModulesV2/components/ModuleWrapper/CollapseButton';

import './toastBuilderNewest.scss';

import { VersionHistoryDrawer } from '../AiToastBuilder/VersionHistoryDrawer';
import useEditor from '../../hooks/useEditor';
import { useSnackbar } from '../../provider/snackbarProvider';

import { ToastCompletedModal } from './ToastCompletedModal';

import { NPSModal } from '../Export/Modals/NPSModal';
import { ReferFriendModal } from '../../components/v2/ReferFriendModal/ReferFriendModal';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MainContentModule } from '../AiCeremonyDesigner/MainContent/MainContentModule';
import { FeedbackModal } from '../Export/Modals/FeedbackModal';
import { useToastBuilder } from '../../provider/toastBuilderProvider';
import useLoader from '../../hooks/useLoader';
import useToast from '../../hooks/useToast';
import { CouplesNotes } from '../../components/v2/CouplesNotes/CouplesNotes';
import { CTAProvider } from '../../provider/ctaProvider';
import { Question } from '../Modules/common/moduleQuestion/ModuleQuestion';
import { BuilderHeader } from '../../components/v2/BuilderHeader/BuilderHeader';
import SpeechwritingAddonModal from '../../components/v2/SpeechwritingAddonModal/SpeechwritingAddonModal';

import { set } from 'dot-object';

import { useAuth } from '../../provider/authProvider';
import { getUserCustomField } from '../../helpers/userHelper';
import { BuilderHeaderNew } from '../../components/v2/BuilderHeaderNew/BuilderHeaderNew';

export type ContentProps = {
  tips: TipsIdeas[];
  ideas: TipsIdeas[];
  editorValue: string;
  setEditorValue: React.Dispatch<React.SetStateAction<string>>;
  insertText: (text: string) => void;
  ceremony: Ceremony;
  QAStatus: QuestionAnswerStatusObj;
  onSendReminder: () => Promise<void>;
  ceremonyId: string;
  saved: boolean;
  saving: boolean;
  couple1: Member;
  couple2: Member;
  officiant?: Member;
  totalMinutes: number;
  officiantRemarksMinutes: number;
  vowsMinutes: number;
  declarationMinutes: number;
  ritualMinutes: number;
  onPreviewClick: () => void;
};

const ToastBuilderNewest = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [requiredQuestions, setRequiredQuestions] = useState<number[]>([]);
  const [questionsValue, setQuestionsValue] = useState<Question[]>([]);
  const [speechFocusQuestion, setSpeechFocusQuestion] =
    useState<Question | null>();
  const [questionChanged, setQuestionChanged] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'Responses' | 'Toast'>(
    'Toast'
  );
  const [speechWritingAddonsModalOpen, setSpeechWritingAddonsModalOpen] =
    useState<boolean>(false);

  const { user, getProfile } = useAuth();

  const {
    readOnly,
    ceremony,
    couple1,
    couple2,
    currentUser,
    officiant,
    fetchCeremony,
    fetchFriendsWithQuestions,
    setSaved,
    setSaving,
  } = useOutput();

  const { fetchAllQuestions } = useAllQuestions();
  const { openSnackBar } = useSnackbar();
  const size = useWindowSize();
  const { isShowing, showLoader, hideLoader } = useLoader();

  const { filteredModules, couplesNote, toastTone, fetchToast } = useToast();

  const members = { couple1, couple2, officiant, currentUser };

  const {
    getQAStatus,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    aiGeneratedTextIndex,
    aiGeneratedText,
    setAiGeneratedTextRendered,
    aiGeneratedTextRendered,
    setAiGeneratedTextIndex,
    textGenerating,
    setTextGenerating,
    setTextGenerateComplete,
    selectedVersionId,
    versions,
    setSelectedVersionId,
    setVersions,
    setAiGeneratedText,
    setDraftingModalOpen,
    generateDraftInitiated,
    setGenerateDraftInitiated,
    setGenerationError,
    getMomentOfLatestVersion,
  } = useToastBuilder();

  const {
    value: valueReceptionToast,
    setValue: setValueReceptionToast,
    insertText: insertTextReceptionToast,
  } = useEditor({
    ceremony_id: params.ceremonyId || '',
    module: outputModules.friends_reception_toast_ai,
    setSavedCallback: setSaved,
    setSavingCallback: setSaving,
  });

  const {
    value: valueEngagementToast,
    setValue: setValueEngagementToast,
    insertText: insertTextEngagementToast,
  } = useEditor({
    ceremony_id: params.ceremonyId || '',
    module: outputModules.friends_engagement_toast_ai,
    setSavedCallback: setSaved,
    setSavingCallback: setSaving,
  });

  const {
    value: valueRehearsalToast,
    setValue: setValueRehearsalToast,
    insertText: insertTextRehearsalToast,
  } = useEditor({
    ceremony_id: params.ceremonyId || '',
    module: outputModules.friends_rehearsal_toast_ai,
    setSavedCallback: setSaved,
    setSavingCallback: setSaving,
  });

  const {
    value: valueStandaloneToast,
    setValue: setValueStandaloneToast,
    insertText: insertTextStandaloneToast,
    generated: generatedStandaloneToast,
  } = useEditor({
    ceremony_id: params.ceremonyId || '',
    module: outputModules.guest_standalone_toast_ai,
    setSavedCallback: setSaved,
    setSavingCallback: setSaving,
  });

  const insertText = useCallback(
    (module: outputModules, value: string) => {
      switch (module) {
        case outputModules.friends_reception_toast_ai:
          insertTextReceptionToast(value);
          break;
        case outputModules.friends_engagement_toast_ai:
          insertTextEngagementToast(value);
          break;
        case outputModules.friends_rehearsal_toast_ai:
          insertTextRehearsalToast(value);
          break;
        case outputModules.guest_standalone_toast_ai:
          insertTextStandaloneToast(value);
          break;
        default:
      }
    },
    [
      insertTextReceptionToast,
      insertTextEngagementToast,
      insertTextRehearsalToast,
      insertTextStandaloneToast,
    ]
  );

  const setEditorValue = useCallback(
    (
      module: outputModules,
      generated: boolean,
      value: string,
      initialLoad?: boolean,
      saveToVersionHistory?: boolean
    ) => {
      switch (module) {
        case outputModules.friends_engagement_toast_ai:
          setValueEngagementToast(
            value,
            generated,
            initialLoad,
            saveToVersionHistory
          );
          break;
        case outputModules.friends_reception_toast_ai:
          setValueReceptionToast(
            value,
            generated,
            initialLoad,
            saveToVersionHistory
          );
          break;
        case outputModules.friends_rehearsal_toast_ai:
          setValueRehearsalToast(
            value,
            generated,
            initialLoad,
            saveToVersionHistory
          );
          break;
        case outputModules.guest_standalone_toast_ai:
          setValueStandaloneToast(
            value,
            generated,
            initialLoad,
            saveToVersionHistory
          );
          break;
      }
    },
    [
      setValueEngagementToast,
      setValueReceptionToast,
      setValueRehearsalToast,
      setValueStandaloneToast,
    ]
  );

  const getEditorValue = useCallback(
    (module: outputModules) => {
      let result = '';
      switch (module) {
        case outputModules.friends_engagement_toast_ai:
          result = valueEngagementToast;
          break;
        case outputModules.friends_reception_toast_ai:
          result = valueReceptionToast;
          break;
        case outputModules.friends_rehearsal_toast_ai:
          result = valueRehearsalToast;
          break;
        case outputModules.guest_standalone_toast_ai:
          result = valueStandaloneToast;
          break;
      }
      return result;
    },
    [
      valueEngagementToast,
      valueReceptionToast,
      valueRehearsalToast,
      valueStandaloneToast,
    ]
  );

  const {
    loadInvites,
    setLoadInvites,
    setLoadFeedback,
    showCompletionModal,
    exitCompletionModal,
    showNPSModal,
    showInvites,
    showFeedback,
    finishFeedback,
    setNPSUserId,
    setNPSCeremonyId,
    setNPSSection,
    userSubmittedNPS,
    fetchSubmittedNPS,
  } = useNPS();

  const getOutput = useCallback(
    async (ceremonyId: string, filteredModules: outputModules[]) => {
      showLoader();
      if (ceremonyId && filteredModules && filteredModules.length > 0) {
        try {
          const outputResponse: OutputResponse = await getOutputData(
            ceremonyId,
            true,
            params.guestId
          );
          if (outputResponse.success) {
            hideLoader();

            setVersions(outputResponse.data);

            if (
              outputResponse.data[0] &&
              filteredModules.indexOf(outputResponse.data[0].module) >= 0
            ) {
              setEditorValue(
                outputResponse.data[0].module,
                outputResponse.data[0].generated,
                outputResponse.data[0].content,
                true
              );
            }

            return outputResponse.data;
          } else {
            hideLoader();
            openSnackBar(outputResponse.message, 'error');
          }
        } catch (err) {
          hideLoader();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          openSnackBar(getAPIErrorMessage(err as any), 'error');
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredModules]
  );
  const handleGenerateDraft = async (
    ceremonyId: string,
    redirectPath?: string,
    skipModal = false
  ) => {

    //This is a hack fix in order to prevent Option Questions to revert to previous answers
    //Should investigate how to fix this properly
    void fetchQuestions(Module.GuestToastAi, ceremonyId.toString());

    const versions = await getOutput(ceremonyId, filteredModules);

    const numberOfGeneratedVersions = versions
      ? versions.filter((version) => version.generated).length
      : 0;

    await getProfile(() => {});

    const modalSkippedByUser = getUserCustomField(
      user,
      `skip-modal-${ceremonyId}-${appToolOptions.toast_builder}`
    );

    // if (numberOfGeneratedVersions > 0 && !skipModal && !modalSkippedByUser) {
    //   setSpeechWritingAddonsModalOpen(true);
    //   return false;
    // }

    setAiGeneratedTextIndex(0);
    setAiGeneratedText('');
    setAiGeneratedTextRendered('');
    setTextGenerating(true);
    setGenerationError(false);
    setTextGenerateComplete(false);
    setTimeout(() => {
      setDraftingModalOpen(true);
    }, 500);

    if (
      ceremonyId &&
      !isNaN(parseInt(ceremonyId)) &&
      filteredModules.length > 0
    ) {
      try {
        const result = await generateToastbuilderText({
          ceremonyId: parseInt(ceremonyId),
        });
        if (result.success) {
          setEditorValue(filteredModules[0], true, result.text, false, true);
          setAiGeneratedText(result.text);
          if (redirectPath) {
            navigate(redirectPath);
          }
        }
      } catch (err) {
        console.log(err);
        // openSnackBar('Error generating draft', 'error');
        setGenerationError(true);
      } finally {
      }
    }
  };

  useEffect(() => {
    document.title = `Provenance App – Toast Builder`;
  }, []);

  useEffect(() => {
    if (params.ceremonyId) {
      void fetchToast(params.ceremonyId, params.guestId);
    }
  }, [params.ceremonyId, params.guestId]);

  useEffect(() => {
    if (
      generateDraftInitiated &&
      params.ceremonyId &&
      filteredModules.length > 0
    ) {
      void handleGenerateDraft(params.ceremonyId);
      setGenerateDraftInitiated(false);
    }
    document.title = `Provenance App – Toast Builder`;
  }, [generateDraftInitiated, filteredModules]);

  useEffect(() => {
    if (aiGeneratedTextIndex < aiGeneratedText.length) {
      const random = Math.floor(Math.random() * 50 + 1);
      setTimeout(() => {
        const nextPiece = aiGeneratedText.slice(
          aiGeneratedTextIndex,
          aiGeneratedTextIndex + random
        );

        setAiGeneratedTextRendered(aiGeneratedTextRendered + nextPiece);
        setEditorValue(
          filteredModules[0],
          false,
          aiGeneratedTextRendered + nextPiece,
          true
        );
        setAiGeneratedTextIndex(aiGeneratedTextIndex + random);
      }, Math.floor(Math.random() * 50 + 1));
    } else {
      if (textGenerating && aiGeneratedTextIndex > 0) {
        setTextGenerating(false);
        setTextGenerateComplete(true);
      }
    }
  }, [aiGeneratedText, aiGeneratedTextIndex]);

  useEffect(() => {
    document.title = `Provenance App – AI Toast Builder`;
    //TODO - find out is this needed
    //setActiveModule(outputModules.guest_standalone_toast_ai);
    setAiGeneratedTextIndex(0);
    setAiGeneratedTextRendered('');
  }, []);

  useEffect(() => {
    if (ceremony && selectedVersionId) {
      const outputResponse = versions.find(
        (version) => version.id === selectedVersionId
      );

      if (outputResponse) {
        setEditorValue(
          outputResponse.module,
          false,
          outputResponse.content,
          false,
          true
        );
      }
    }
  }, [selectedVersionId]);

  const ceremonyId = useParams()?.ceremonyId;

  /* Setting the NPS provider with the correct data */
  useEffect(() => {
    if (currentUser?.user_id) setNPSUserId(currentUser?.user_id);
    if (ceremony?.id) setNPSCeremonyId(ceremony?.id);
    setNPSSection('toast');
    fetchSubmittedNPS();
  }, [ceremony, currentUser]);

  useEffect(() => {
    if (!params.ceremonyId) {
      navigate('/dashboard');
    }
    if (params.ceremonyId) {
      void fetchAllQuestions(params.ceremonyId);
      void getOutput(params.ceremonyId, filteredModules);
      void fetchCeremony(params.ceremonyId);
      void fetchFriendsWithQuestions(params.ceremonyId);
      void getQAStatus(params.ceremonyId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.ceremonyId, filteredModules]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showLeftContainer, setShowLeftContainer] = useState(true);

  const toastModule = useQuestions.Modules.GuestToastAi;
  const { questions, fetchQuestions } = useQuestions({
    ceremonyId,
    module: toastModule,
  });

  const handleConditionallyMarkingQuestionsAsRequired = () => {
    //if question 4 has an answer
    if (
      speechFocusQuestion &&
      speechFocusQuestion?.answers?.[0]?.option_id &&
      speechFocusQuestion.options
    ) {
      const wantedAnswerOptionId = speechFocusQuestion.answers[0].option_id;

      const optionText = speechFocusQuestion?.options.find(
        (option) => option.id === parseInt(wantedAnswerOptionId)
      )?.option;

      if (
        optionText ===
        'Mostly focus on {{PARTNER_A}}, but acknowledge {{PARTNER_B}}'
      ) {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 6, 7]);
      } else if (
        optionText ===
        'Mostly focus on {{PARTNER_B}}, but acknowledge {{PARTNER_A}}'
      ) {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 8, 9]);
      } else if (optionText === 'Speak about the couple as a whole') {
        setRequiredQuestions([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
      }
    } else {
      setRequiredQuestions([0, 1, 2, 3, 4]);
    }
  };

  useEffect(() => {
    if (questions && (!questionsValue || questionsValue.length === 0)) {
      setQuestionsValue(questions);
    } else if (questions && questionsValue && questionsValue.length > 0) {
      const wantedQuestion = questions.find(
        (question) =>
          question.question ===
          'Would you like your speech to focus on the couple as a whole or on one of partners?'
      );
      if (wantedQuestion) {
        setSpeechFocusQuestion(wantedQuestion);
      }
    }
  }, [questions]);

  useEffect(() => {
    handleConditionallyMarkingQuestionsAsRequired();
  }, [speechFocusQuestion]);

  useEffect(() => {
    if (ceremony && !questions) {
      void fetchQuestions(Module.GuestToastAi, ceremony.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony]);

  const handleQuestionSaved = (questionText: string) => {
    if (
      questionText ===
      'Would you like your speech to focus on the couple as a whole or on one of partners?'
    ) {
      setQuestionChanged(true);
    }
  };

  useEffect(() => {
    if (ceremony && questionChanged === true) {
      setQuestionChanged(false);
      void fetchQuestions(Module.GuestToastAi, ceremony.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionChanged]);

  if (!ceremony) {
    return null;
  }

  const handleDownloadPdf = async (
    downloadType: 'output' | 'toast' | 'vows'
  ) => {
    ReactGA.event({
      category: 'Output',
      action: 'Button click',
      label: 'Download PDF',
    });

    if (ceremony) {
      //showLoader();
      const blob = await downloadOutput(
        ceremony.id.toString(),
        downloadType,
        false,
        false,
        undefined,
        currentUser?.id
      );
      const url = window.URL.createObjectURL(blob);

      // window.open(url, '_blank'); // To open the pdf in a new tab

      const a = document.createElement('a');
      a.href = url;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

      a.download = 'Toast';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      //hideLoader();
      if (currentUser && currentUser.id) {
        const checklistItemForSaving: ChecklistItem = {
          ceremony_id: ceremony.id,
          member_id: currentUser.id,
          type: ChecklistItemType.toasts,
        };
        await saveChecklistItem(checklistItemForSaving);
      }
    }
  };

  const renderQuestions = () => (
    <div
      className={`py-6 px-[10%] ${
        size.width < 1024
          ? ''
          : 'border border-l-0 border-y-0 border-forest-100'
      }`}
    >
      <Typography type='display-200'>Your Responses</Typography>
      <Typography className='mt-2' type='body-400'>
        Below are your responses from the Toast Builder Questionnaire. You can
        either change your responses and regenerate a new toast, or freely edit
        your toast on the right.
      </Typography>
      <Separator className='my-10 border-forest-100' />
      {couplesNote && <CouplesNotes text={couplesNote} />}

      {ceremony &&
        questions &&
        questions.length > 0 &&
        requiredQuestions &&
        questions
          .filter((_questionData, index) => requiredQuestions.includes(index))
          .sort((a, b) => {
            if (a.order && b.order) {
              return a.order < b.order ? -1 : 1;
            } else if (a.order != null && b.order === null) {
              return -1;
            } else if (a.order === null && b.order != null) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((questionData, questionIndex) => (
            <ModuleQuestion
              key={questionData.id}
              ceremonyId={ceremony.id}
              question={questionData}
              questionNumber={questionIndex + 1}
              numberOfQuestions={questions.length}
              members={members}
              required={true}
              onQuestionSaved={() => handleQuestionSaved(questionData.question)}
              shouldRerender={true}
            />
          ))}
      {ceremony &&
        questions &&
        questions.length > 0 &&
        requiredQuestions &&
        questions
          .filter((_questionData, index) => !requiredQuestions.includes(index))
          .sort((a, b) => {
            if (a.order && b.order) {
              return a.order < b.order ? -1 : 1;
            } else if (a.order != null && b.order === null) {
              return -1;
            } else if (a.order === null && b.order != null) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((questionData, questionIndex) => (
            <ModuleQuestion
              key={questionData.id}
              ceremonyId={ceremony.id}
              question={questionData}
              questionNumber={questionIndex + requiredQuestions.length + 1}
              numberOfQuestions={questions.length}
              members={members}
              required={false}
              shouldRerender={true}
            />
          ))}
    </div>
  );

  return (
    <CTAProvider
      totalQuestions={questions?.length || 0}
      requiredQuestions={requiredQuestions.length}
      module={toastModule}
    >
      <div className='w-screen h-screen overflow-hidden bg-cosmicLatte-primary'>
        <VersionHistoryDrawer
          isOpen={versionHistoryModalOpen}
          onClose={() => setVersionHistoryModalOpen(false)}
          setSelectedVersionId={(versionId) => setSelectedVersionId(versionId)}
          versions={versions}
          variant='toast'
        />

        <ToastCompletedModal
          onCancelClick={() => {
            exitCompletionModal();
            navigate(`/ceremony/${String(params.ceremonyId)}/toastbuilder`);
          }}
          onDownloadPDFClick={() => {
            !userSubmittedNPS && showNPSModal();
            void handleDownloadPdf('toast');
            exitCompletionModal();
          }}
        />

        <FeedbackModal
          onCancelClick={finishFeedback}
          onContinueClick={finishFeedback}
        />
        <ReferFriendModal
          isOpen={loadInvites}
          onClose={() => setLoadInvites(false)}
        />

        <FeedbackModal
          onCancelClick={finishFeedback}
          onContinueClick={finishFeedback}
        />
        <ReferFriendModal
          isOpen={loadInvites}
          onClose={() => setLoadInvites(false)}
        />

        <BuilderHeaderNew
          className='shrink-0'
          onDownload={() => {
            showCompletionModal();

            currentUser?.id &&
              void changeSpeechOutputCompletionStatus(ceremony.id, {
                module: filteredModules[0],
                status: true,
                mid: currentUser.id,
              });
          }}
          onClose={() => {
            setAiGeneratedText('');
            navigate(`/ceremony/${ceremony.id.toString()}/guest`);
          }}
          onVersionHistoryClick={async () => {
            await getOutput(params.ceremonyId || '', filteredModules);
            setVersionHistoryModalOpen(true);
          }}
          onRegenerateClick={() => {
            if (params.ceremonyId) {
              void handleGenerateDraft(params.ceremonyId);

              void generalLog({
                ceremony_id: ceremony.id,
                activity: `Generate - ToastAI`,
              });
            }
          }}
          lastVersionSaved={getMomentOfLatestVersion()}
          ceremonyId={ceremonyId ? Number(ceremonyId) : undefined}
          disableBasedOnModuleStatus={true}
        />
        <div className='py-6 flex justify-center w-full flex-1 lg:hidden'>
          <div
            onClick={() => setSelectedTab('Responses')}
            className={`rounded-xl px-4 py-2 ${
              selectedTab === 'Responses' ? 'bg-cosmicLatte-dark' : ''
            }`}
          >
            <Typography type='display-50'>Responses</Typography>
          </div>
          <div
            onClick={() => setSelectedTab('Toast')}
            className={`rounded-xl px-4 py-2 ${
              selectedTab === 'Toast' ? 'bg-cosmicLatte-dark' : ''
            }`}
          >
            <Typography type='display-50'>Toast</Typography>
          </div>
        </div>
        <div
          className={`w-full relative px-4 md:container md:mx-auto h-full overflow-hidden flex flex-row`}
          ref={containerRef}
        >
          {size.width >= 1024 && (
            <Collapse
              className='ToastBuilder-layout overflow-y-auto h-screen'
              {...{ in: showLeftContainer, orientation: 'horizontal' }}
            >
              {renderQuestions()}
            </Collapse>
          )}
          {size.width < 1024 && selectedTab === 'Responses' && (
            <div className='h-screen overflow-y-auto'>{renderQuestions()}</div>
          )}
          {((size.width < 1024 && selectedTab === 'Toast') ||
            size.width >= 1024) && (
            <div
              className={`${
                size.width < 1024
                  ? 'h-[calc(100%-159px)]'
                  : 'h-[calc(100%-67px)]'
              } flex flex-1 bg-white flex-col `}
            >
              <RichTextToolbar />
              {size.width >= 1024 && (
                <CollapseButton
                  className='absolute'
                  onClick={() => setShowLeftContainer((old) => !old)}
                  collapsed={!showLeftContainer}
                  text='Responses'
                />
              )}

              <div
                className={`p-5 flex-1 overflow-auto w-full max-w-[570px] self-center`}
              >
                <div className='w-full flex flex-col md:flex-row justify-center text-center md:text-start md:justify-between items-center md:items-start'>
                  <div>
                    <Typography type='display-200'>Your Toast</Typography>
                    {currentUser && (
                      <Typography
                        className='text-forest-300 my-2'
                        type='body-200'
                        variant='functional-low'
                      >{`${getTextDurationString(
                        getEditorValue(filteredModules[0])
                      )} min read · ${toastTone ? toastTone : ''}`}</Typography>
                    )}
                  </div>
                </div>

                {filteredModules.indexOf(
                  outputModules.friends_reception_toast_ai
                ) >= 0 && (
                  <MainContentModule
                    module={outputModules.friends_reception_toast_ai}
                    value={valueReceptionToast}
                    setValue={setValueReceptionToast}
                    insertText={insertText}
                    ceremonyId={ceremony.id.toString()}
                    readOnly={readOnly}
                    fullHeight={true}
                  />
                )}
                {filteredModules.indexOf(
                  outputModules.friends_engagement_toast_ai
                ) >= 0 && (
                  <MainContentModule
                    module={outputModules.friends_engagement_toast_ai}
                    value={valueEngagementToast}
                    setValue={setValueEngagementToast}
                    insertText={insertText}
                    ceremonyId={ceremony.id.toString()}
                    readOnly={readOnly}
                    fullHeight={true}
                  />
                )}
                {filteredModules.indexOf(
                  outputModules.friends_rehearsal_toast_ai
                ) >= 0 && (
                  <MainContentModule
                    module={outputModules.friends_rehearsal_toast_ai}
                    value={valueRehearsalToast}
                    setValue={setValueRehearsalToast}
                    insertText={insertText}
                    ceremonyId={ceremony.id.toString()}
                    readOnly={readOnly}
                    fullHeight={true}
                  />
                )}
                {filteredModules.indexOf(
                  outputModules.guest_standalone_toast_ai
                ) >= 0 && (
                  <MainContentModule
                    module={outputModules.guest_standalone_toast_ai}
                    value={valueStandaloneToast}
                    setValue={setValueStandaloneToast}
                    insertText={insertText}
                    ceremonyId={ceremony.id.toString()}
                    readOnly={readOnly}
                    fullHeight={true}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <SpeechwritingAddonModal
          isOpen={speechWritingAddonsModalOpen}
          onClose={() => {
            setSpeechWritingAddonsModalOpen(false);
          }}
          appTool={appToolOptions.toast_builder}
          onContinue={() => {
            setSpeechWritingAddonsModalOpen(false);
            params.ceremonyId &&
              void handleGenerateDraft(params.ceremonyId, undefined, true);
          }}
        />

        <NPSModal
          onContinueHigh={showInvites}
          onContinueLow={showFeedback}
          threshold={8}
        />
      </div>
    </CTAProvider>
  );
};

export default ToastBuilderNewest;

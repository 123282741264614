import { Buffer } from 'buffer';

import { useEffect, useState } from 'react';
import ReactFbPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSearchParams } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';

import {
  OrderResponse,
  confirmpaymentSession,
  NewPaymentBody,
  Order,
  OrderSources,
  confirmMultipleOrders,
} from '../../../api/paywall';
import Loader from '../../../components/Loader/loader';
import SnackbarComponent from '../../../components/Snackbar/Snackbar';
import HearAboutModal from '../../../components/v2/HearAboutModal/HearAboutModalNew';
import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../../helpers/helper';
import useLoader from '../../../hooks/useLoader';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useAuth } from '../../../provider/authProvider';
import { useChecklist } from '../../../provider/checklistProvider';
import paymentSuccessPng from '../images/payment-success.png';
import '../index.scss';

import PayConfirmationInner from './PayConfirmationInner';
import {
  getButtonDetails,
  getMainText,
  getTitleText,
} from './payConfirmationHelper';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    dataLayer: any[];
  }
}

type OrderDetails = {
  order_source: OrderSources;
  prodDataName: string;
};

const PayConfirmation = () => {
  const auth = useAuth();
  const { isShowing, hideLoader } = useLoader();
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();

  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();
  const [hashedEmail, setHashedEmail] = useState<string>('');

  const status = searchParams.get('st') === 't';
  const ref = searchParams.get('rf');
  const refs = searchParams.get('rfs');
  const isMerchandising = searchParams.get('m') === 't';
  const isOrdination = searchParams.get('o') === 't';
  const isGiftcard = searchParams.get('g') === 't';
  const orderDetailsb64 = searchParams.get('od');
  const orderDetails: OrderDetails = orderDetailsb64
    ? JSON.parse(Buffer.from(orderDetailsb64, 'base64').toString('utf8'))
    : undefined;

  const [showHearAboutModal, setShowHearAboutModal] = useState(false);

  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  useEffect(() => {
    if (ref) {
      void confirmPaymentRef(ref);
    }
  }, [ref]);

  useEffect(() => {
    if (refs) {
      void confirmPaymentRefs(refs);
    }
  }, [refs]);

  useEffect(() => {
    if (hashedEmail) {
      // window.gtag('set', 'user_data', {
      //   sha256_email_address: hashedEmail,
      // });
    }
  }, [hashedEmail]);

  useEffect(() => {
    if (order) {
      if (isGiftcard || isMerchandising) {
        setShowHearAboutModal(false);
      } else {
        setShowHearAboutModal(true);
      }
    }
  }, [isGiftcard, isMerchandising, order]);

  useEffect(() => {
    if (
      order &&
      order.payment_type &&
      (order.payment_type === 'wp_pay_per_ceremony' ||
        order.payment_type === 'subscription')
    ) {
      void sendCustomerIoEventHandler('WP Payment Confirmed');

      void saveMemberChecklistItemUsingIdentifier(
        {
          completed: true,
        },
        ChecklistItemIdentifier.ADD_REST_OF_CLIENTS,
        true
      );
    }
    if (order && order.type === 'guest_toast_builder') {
      void sendCustomerIoEventHandler('Guest Purchase Completed');
    }
    if (order && order.is_ordination_order) {
      void sendCustomerIoEventHandler('Ordination Package Purchase Completed');
    }
  }, [order]);

  const hash = async (string: string) => {
    string = string.trim().toLowerCase();
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    setHashedEmail(hashHex);
  };

  useEffect(() => {
    if (status) {
      if (order) {
        order.user?.email && void hash(order.user?.email);

        // ReactFbPixel.track('Purchase', {
        //   currency: 'USD',
        //   value: order.actual_amount,
        // });
        void ReactFbPixel.fbq(
          'track',
          'Purchase',
          {
            currency: 'USD',
            value: order.actual_amount,
          },
          {
            eventID: `prov_${order.id}`,
          }
        );

        void ReactGA.event({
          category: 'PayWall',
          action: 'OrderPaymentSuccessful',
          label: 'OrderPaymentSuccessful',
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'order_complete',
          order_id: order.id,
          order_value: order.actual_amount,
          order_currency: 'USD',
          enhanced_conversion_data: {
            email: order.user?.email,
          },
        });

        // window.gtag('event', 'conversion', {
        //   send_to: 'AW-11021677486/qoHXCMOh6JoYEK7nxYcp',
        //   value: order.actual_amount,
        //   currency: 'USD',
        //   transaction_id: order.id,
        //   user_data: {
        //     email: order.user?.email,
        //   },
        // });

        void ReactGA.event('purchase', {
          transaction_id: order.id,
          value: order.actual_amount,
          currency: 'USD',
        });

        void TiktokPixel.track('CompletePayment', {
          content_type: 'product',
          quantity: 1,
          content_id: order.product_id,
          currency: 'USD',
          value: order.actual_amount,
        });
      }
    }
  }, [order]);

  const confirmPaymentRef = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref || '',
      };
      const sessionResponse: OrderResponse = await confirmpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        setOrder(sessionResponse.order);
        status
          ? openSnackBar('Payment processed successfully', 5000, 'success')
          : openSnackBar('Payment could not be processed', 5000, 'error');
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const confirmPaymentRefs = async (refs: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: refs || '',
      };
      const sessionResponse: OrderResponse = await confirmMultipleOrders(
        payBody
      );
      if (sessionResponse.success && sessionResponse.order) {
        //TODO - we have multiple orders here - what should happen?
        setOrder(sessionResponse.order);
        status
          ? openSnackBar('Payment processed successfully', 5000, 'success')
          : openSnackBar('Payment could not be processed', 5000, 'error');
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const buttonDetails = getButtonDetails(
    isOrdination,
    order,
    status,
    orderDetails?.order_source,
    auth?.user
  );

  return (
    <div className='bg-containerbg min-h-4/5 flex flex-col space-between items-center justify-center overflow-x-hidden pb-10'>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />

      <PayConfirmationInner
        title={getTitleText(status, isOrdination)}
        message={getMainText(status, isGiftcard, isMerchandising, isOrdination)}
        buttonText={buttonDetails.text}
        buttonLink={buttonDetails.redirect}
        status={status}
        secondaryButtonText={'Checkout Now'}
        secondaryButtonLink={`/pay-summary/${order?.ceremony_id || ''}`}
      />

      {status &&
        order &&
        order.id &&
        auth.user &&
        process.env.REACT_APP_ENV === 'production' && (
          <img
            id='_SHRSL_img_1'
            src={`https://www.shareasale.com/sale.cfm?tracking=${order.id}&amount=${order.actual_amount}&merchantID=135962&v=dev1.5&transtype=sale&currency=${order.currency}`}
            width='1'
            height='1'
          ></img>
        )}

      {showHearAboutModal && (
        <HearAboutModal
          onCancelClick={() => setShowHearAboutModal(false)}
          modalOpen={showHearAboutModal}
        />
      )}
    </div>
  );
};

export default PayConfirmation;

import { FieldProps } from 'formik';

import CustomCheckBox from '../../../components/forms/CustomCheckBox';

interface CustomInputProps {
  label: string;
  setChanged: () => void;
  className?: string;
  error?: boolean;
  helperText: string;
  reverseColor?: boolean
}

export const CustomFormikCheckBox = ({
  field,
  form,
  className,
  setChanged,
  error,
  helperText,
  ...props
}: 
CustomInputProps & FieldProps<string>) => {

  return (
    <CustomCheckBox
      className={className}
      value={field.value}
      name={field.name}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(ev);
        if (setChanged) {
          setChanged();
        }
      }}
      error={error}
      helperText={helperText}
      {...props}
    />
  );
};
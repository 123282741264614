export enum ChecklistItemIdentifier {
  //FOR COUPLE
  //three months before the wedding
  CHECKOUT_CEREMONY_BUILDER = 'checkout_ceremony_builder',
  INVITE_COLLABORATORS = 'invite_collaborators',
  INVITE_OFFICIANT = 'invite_officiant',
  COMPLETE_CHAPTER_ONE = 'complete_chapter_one',
  START_VOWS = 'start_vows',
  GATHER_REFLECTIONS = 'gather_reflections',
  //two months before the wedding
  COMPLETE_CHAPTER_TWO = 'complete_chapter_two',
  COMPLETE_CHAPTER_THREE = 'complete_chapter_three',
  COMPLETE_CHAPTER_FOUR = 'complete_chapter_four',
  COMPLETE_CHAPTER_FIVE = 'complete_chapter_five',
  COMPLETE_CHAPTER_SIX = 'complete_chapter_six',
  //one month before the wedding
  FINALIZE_CEREMONY_SCRIPT = 'finalize_ceremony_script',
  DOWNLOAD_PROCESSIONAL_GUIDE = 'download_processional_guide',
  //FOR OFFICIANT - only
  REVIEW_PARTNER_A_VOWS = 'review_partner_a_vows',
  //FOR OFFICIANT - only
  REVIEW_PARTNER_B_VOWS = 'review_partner_b_vows',
  INVITE_PARTNER_TO_VOWS = 'invite_partner_to_vows',
  WRITE_WELCOME_TOAST = 'write_welcome_toast',
  GET_SPEECHWRITING_HELP = 'get_speechwriting_help',
  GIVE_ACCESS_TO_TOASTBUILDER = 'give_access_to_toastbuilder',
  ORDAINED = 'ordained',
  //one week before the wedding
  REVIEW_SCRIPT_WITH_OFFICIANT = 'review_script_with_officiant',
  REVIEW_SCRIPT_WITH_COUPLE = 'review_script_with_couple',
  DOWNLOAD_VOWS = 'download_vows',
  DOWNLOAD_SCRIPT = 'download_script',
  GET_PUBLIC_SPEAKING_HELP = 'get_public_speaking_help',
  DOWNLOAD_CHECKLIST_PDF = 'download_checklist_pdf',
  DOWNLOAD_PROCESSIONAL_TIPS_PDF = 'download_processional_tips_pdf',
  //after the wedding
  REFER_FRIENDS = 'refer_friends',
  WRITE_THANK_YOU_NOTES = 'write_thank_you_notes',
  GIVE_FEEDBACK = 'give_feedback',
  BUY_WEDDING_BOOK = 'buy_wedding_book',
  GIFT_PROVENANCE = 'gift_provenance',
  //FOR WP
  PLANNER_PORTAL_DEMO = 'planner_portal_demo',
  INVITE_FIRST_CLIENT = 'invite_first_client',
  REVIEW_SPEECHES = 'review_speeches',
  INVITE_TOAST_GIVERS = 'invite_toast_givers',
  EXPLORE_RESOURCES = 'explore_resources',
  ADD_REST_OF_CLIENTS = 'add_rest_of_clients',
  UNIQUE_LINK = 'unique_link',
  REFER_CLIENTS = 'refer_clients',
  //FOR GUEST
  WRITE_TOAST = 'write_toast',
  GUEST_GET_SPEECHWRITING_HELP = 'guest_get_speechwriting_help',
  GUEST_GET_PUBLIC_SPEAKING_HELP = 'guest_get_public_speaking_help',
  REVIEW_TOAST_WITH_COUPLE = 'review_toast_with_couple',
  DOWNLOAD_TOAST = 'download_toast',
  GUEST_REFER_FRIENDS = 'guest_refer_friends',
  GUEST_WATCH_DEMO = 'guest_watch_demo',
  GUEST_GIVE_FEEDBACK = 'guest_give_feedback',
}

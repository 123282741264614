import { Member } from '../../api/ceremony';
import {
  ChecklistItemDefinition,
  MemberChecklistItem,
} from '../../api/checklistItemDefinitions';

import { ChecklistItemIdentifier } from './identifiers';

const skippableItemsList = [
  ChecklistItemIdentifier.INVITE_COLLABORATORS,
  ChecklistItemIdentifier.INVITE_OFFICIANT,
  ChecklistItemIdentifier.GATHER_REFLECTIONS,
  ChecklistItemIdentifier.START_VOWS,
  ChecklistItemIdentifier.INVITE_PARTNER_TO_VOWS,
  ChecklistItemIdentifier.WRITE_WELCOME_TOAST,
  ChecklistItemIdentifier.GET_SPEECHWRITING_HELP,
  ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER,
  ChecklistItemIdentifier.GET_PUBLIC_SPEAKING_HELP,
  ChecklistItemIdentifier.REFER_FRIENDS,
  ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES,
  ChecklistItemIdentifier.GIVE_FEEDBACK,
  ChecklistItemIdentifier.BUY_WEDDING_BOOK,
  ChecklistItemIdentifier.GIFT_PROVENANCE,
  ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE,

  // guest checklist items
  ChecklistItemIdentifier.GUEST_GET_SPEECHWRITING_HELP,
  ChecklistItemIdentifier.GUEST_GET_PUBLIC_SPEAKING_HELP,
  ChecklistItemIdentifier.GUEST_REFER_FRIENDS,
  ChecklistItemIdentifier.GUEST_WATCH_DEMO,
  ChecklistItemIdentifier.GUEST_GIVE_FEEDBACK,
];

const wpSkippableItemsList = [
  ChecklistItemIdentifier.PLANNER_PORTAL_DEMO,
  ChecklistItemIdentifier.INVITE_TOAST_GIVERS,
  ChecklistItemIdentifier.EXPLORE_RESOURCES,
  ChecklistItemIdentifier.REFER_CLIENTS,
];

export const getSkipTaskOnClickHandler = (
  item: ChecklistItemDefinition,
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem,
    isWP?: boolean,
    saveOnUserLevel?: boolean
  ) => Promise<void>,
  ceremonyId?: number,
  currentUser?: Member,
  ordained?: boolean
) => {

  if (item.identifier === ChecklistItemIdentifier.ORDAINED) {

    if (ordained) {
      return undefined;
    }

    return async () => {
      const newMemberChecklistItem: MemberChecklistItem = {
        ceremony_id: ceremonyId,
        member_id: currentUser?.id,
        checklist_item_definition_id: item.id,
        skipped: true,
      };
      await saveMemberChecklistItem(newMemberChecklistItem, false, true);
    };
  }
  if (skippableItemsList.includes(item.identifier as ChecklistItemIdentifier)) {
    return async () => {
      const newMemberChecklistItem: MemberChecklistItem = {
        ceremony_id: ceremonyId,
        member_id: currentUser?.id,
        checklist_item_definition_id: item.id,
        skipped: true,
      };
      await saveMemberChecklistItem(newMemberChecklistItem);
    };
  }

  if (
    wpSkippableItemsList.includes(item.identifier as ChecklistItemIdentifier)
  ) {
    return async () => {
      const newMemberChecklistItem: MemberChecklistItem = {
        checklist_item_definition_id: item.id,
        skipped: true,
      };
      await saveMemberChecklistItem(newMemberChecklistItem);
    };
  }

  return undefined;
};

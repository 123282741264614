import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { OrdainedModel } from '../../api/ordained';
import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { usaStates } from '../../data/states-cities';
import { getFirstName, getLastName } from '../../helpers/nameHelper';
import { useDashboard } from '../../provider/dashboardProvider';
import { CustomFormikCheckBox } from '../Modules/friendsAndFamily/CustomFormikCheckbox';
import { CustomFormikInput } from '../Modules/friendsAndFamily/CustomFormikInput';
import { CustomFormikSelect } from '../Modules/friendsAndFamily/CustomFormikSelect';

const OrdainedApplicationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  address: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  //age_confirmed, minister_requested, terms_accepted should all be true
  age_confirmed: Yup.boolean().oneOf(
    [true],
    'You must be at least 18 years old'
  ),
  minister_requested: Yup.boolean().oneOf(
    [true],
    'You must request to be a Provenance certified minister'
  ),
  principles_required: Yup.boolean().oneOf(
    [true],
    'You must agree to the principles of The Provenance Center'
  ),
  terms_accepted: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
});

type OrdainedApplicationForm = {
  onSubmit: (values: OrdainedModel) => Promise<void>;
};

const OrdainedApplicationForm = (props: OrdainedApplicationForm) => {
  const { onSubmit } = props;

  const { currentUser } = useDashboard();

  const formRef = useRef<HTMLDivElement>(null);

  const [initialValues, setInitialValues] = useState<OrdainedModel>({
    firstName: '',
    middleName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    age_confirmed: false,
    minister_requested: false,
    principles_required: false,
    terms_accepted: true,
  });

  useEffect(() => {
    if (currentUser) {
      setInitialValues({
        ...initialValues,
        firstName: getFirstName(currentUser.legal_name),
        lastName: getLastName(currentUser.legal_name),
      });
    }
  }, [currentUser]);

  const scrollToBottom = () => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  return (
    <div
      ref={formRef}
      className='container flex flex-col justify-center max-w-md px-4 sm:px-0 py-4'
    >
      <Typography type='display-600'>Ordination Application</Typography>
      <Typography type='body-400' className='mt-4'>
        To become an ordained minister, complete the form below with your name,
        typed and spelled as you would like it to appear on your certificate and
        with the state where you are officiating:
      </Typography>
      <Formik
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, isValid) => {
          void onSubmit(values);
        }}
        validationSchema={OrdainedApplicationSchema}
      >
        {({ errors, touched, isValid, validateForm }) => (
          <Form className='w-full mt-8'>
            <Field
              name={`firstName`}
              placeholder='Enter your first name'
              component={CustomFormikInput}
              label='First Name'
              required={true}
              helperText={touched.firstName && errors.firstName}
              error={touched.firstName && Boolean(errors.firstName)}
            />
            <Field
              name={`middleName`}
              placeholder='Enter your middle name'
              component={CustomFormikInput}
              label='Middle Name'

            />
            <Field
              name={`lastName`}
              placeholder='Enter your last name'
              component={CustomFormikInput}
              label='Last Name'
              required={true}
              helperText={touched.lastName && errors.lastName}
              error={touched.lastName && Boolean(errors.lastName)}
            />
            {/* <Field
              name={`address`}
              placeholder='Enter your street address'
              component={CustomFormikInput}
              label='Address'
              required={true}
            /> */}
            <div className='flex flex-row'>
              <div className='flex-1'>
                <Field
                  name={`city`}
                  placeholder='Enter your city'
                  component={CustomFormikInput}
                  label='City'
                  required={true}
                  helperText={touched.city && errors.city}
                  error={touched.city && Boolean(errors.city)}
                />
              </div>
              <div className='flex-1 ml-2'>
                <Field
                  name={`state`}
                  placeholder='Enter your state'
                  component={CustomFormikSelect}
                  label='State'
                  required={true}
                  options={usaStates.states.map((s) => {
                    return { value: s.name, label: s.name };
                  })}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}

                />
              </div>
            </div>

            <div className='mt-6'>
              <Field
                name={`age_confirmed`}
                component={CustomFormikCheckBox}
                onClick={scrollToBottom}
                label={
                  <Typography type='body-400' variant='functional-low'>
                    I confirm that I am at least 18 years of age, and that all
                    of the information is truthful and accurate to the best of
                    my knowledge
                  </Typography>
                }
                required={true}
                helperText={touched.age_confirmed && errors.age_confirmed}
                error={touched.age_confirmed && Boolean(errors.age_confirmed)}
              />
            
            </div>
            <div className='mt-6'>
              <Field
                name={`minister_requested`}
                component={CustomFormikCheckBox}
                label={
                  <Typography type='body-400' variant='functional-low'>
                    I request to be a certified minister of The Provenance
                    Center. I undertake this request as a considered,
                    deliberate, and responsible act
                  </Typography>
                }
                required={true}
                helperText={touched.minister_requested && errors.minister_requested}
                error={touched.minister_requested && Boolean(errors.minister_requested)}
              />
            
            </div>
            <div className='mt-6'>
              <Field
                name={`principles_required`}
                component={CustomFormikCheckBox}
                label={
                  <Typography type='body-400' variant='functional-low'>
                    I agree to adhere to the principles of The Provenance
                    Center. To learn more, visit thepco.org
                  </Typography>
                }
                required={true}
                helperText={touched.principles_required && errors.principles_required}
                error={touched.principles_required && Boolean(errors.principles_required)}
              />
            </div>

            {/* <div className='flex flex-row justify-center items-center'>
              <Button className='mt-6' type='submit' disabled={!isValid}>
                Get Ordained
              </Button>
            </div> */}

            {!isValid && (
              <div
                className='sticky bottom-0 mt-2 flex max-w-lg w-full bg-cosmicLatte-primary border-t border-forest-100 justify-center'
                style={{ zIndex: 99 }}
              >
                <Button
                  className='mt-2 mb-2 self-center'
                  type='button'
                  onClick={() => {
                    scrollToBottom();
                    validateForm();
                  }}
                >
                  Get Ordained
                </Button>
              </div>
            )}

            {isValid && (
              <div
                className='sticky bottom-0 mt-2 flex max-w-lg w-full bg-cosmicLatte-primary border-t border-forest-100 justify-center'
                style={{ zIndex: 99 }}
              >
                <Button
                  className='mt-2 mb-2 self-center'
                  type='submit'
                  // disabled={!isValid}
                >
                  Get Ordained
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrdainedApplicationForm;

import { Member } from '../../api/ceremony';
import { capitalizeFirstLetter } from '../helper';
import renderText from '../renderText';

import { ChecklistItemIdentifier } from './identifiers';

export const getTitleByIdentifier = (
  identifier: string,
  couple1?: Member,
  couple2?: Member,
  ordained?: boolean 
) => {

  switch (identifier) {
    case ChecklistItemIdentifier.CHECKOUT_CEREMONY_BUILDER:
      return 'Check out the Ceremony Builder';
    case ChecklistItemIdentifier.INVITE_COLLABORATORS:
      return renderText('invite_collaborators');
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_ONE:
      return 'Complete Chapter 1 of the Ceremony: Welcome & Procession';
    case ChecklistItemIdentifier.INVITE_OFFICIANT:
      return 'Invite your officiant to get legally ordained for free';
    case ChecklistItemIdentifier.GATHER_REFLECTIONS:
      return 'Gather reflections from your friends and family';
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_TWO:
      return "Complete Chapter 2 of the Ceremony: Officiant's Address";
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_THREE:
      return 'Complete Chapter 3 of the Ceremony: Readings & Rituals';
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FOUR:
      return 'Complete Chapter 4 of the Ceremony: Vows Logistics';
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_FIVE:
      return 'Complete Chapter 5 of the Ceremony: Ring Exchange & “I Do’s”';
    case ChecklistItemIdentifier.COMPLETE_CHAPTER_SIX:
      return 'Complete Chapter 6 of the Ceremony: Pronouncement, Kiss & Celebration';
    case ChecklistItemIdentifier.FINALIZE_CEREMONY_SCRIPT:
      return 'Edit and finalize the Ceremony Script';
    case ChecklistItemIdentifier.START_VOWS:
      return 'Start your vows';
    case ChecklistItemIdentifier.INVITE_PARTNER_TO_VOWS:
      return 'Invite your partner to write their vows';
    case ChecklistItemIdentifier.WRITE_WELCOME_TOAST:
      return 'Write your Welcome Toast';
    case ChecklistItemIdentifier.GET_SPEECHWRITING_HELP:
      return renderText('checklist_work_with_speech_writer');
    case ChecklistItemIdentifier.GIVE_ACCESS_TO_TOASTBUILDER:
      return 'Give your guests free access to the #1 Toast Builder';
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_GUIDE:
      return renderText('checklist_download_expert_guide');
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_OFFICIANT:
      return 'Review script with your officiant';
    case ChecklistItemIdentifier.DOWNLOAD_VOWS:
      return 'Download and print your Vows';
    case ChecklistItemIdentifier.DOWNLOAD_SCRIPT:
      return 'Download and print your Ceremony Script';
    case ChecklistItemIdentifier.GET_PUBLIC_SPEAKING_HELP:
      return 'Get expert public speaking tips';
    case ChecklistItemIdentifier.DOWNLOAD_CHECKLIST_PDF:
      return `Download "Provenance's Day of Checklist" PDF`;
    case ChecklistItemIdentifier.DOWNLOAD_PROCESSIONAL_TIPS_PDF:
      return `Download "Processional and Rehearsal Tips" PDF`;
    case ChecklistItemIdentifier.REFER_FRIENDS:
      return 'Refer friends and get $100 in Amazon Gift Cards';
    case ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES:
      return 'Instantly write and send your Thank You Notes';
    case ChecklistItemIdentifier.GIVE_FEEDBACK:
      return 'Tell us how we did';
    case ChecklistItemIdentifier.BUY_WEDDING_BOOK:
      return 'Buy a Wedding Book';
    case ChecklistItemIdentifier.GIFT_PROVENANCE:
      return 'Gift Provenance';
    //OFFICIANT
    case ChecklistItemIdentifier.REVIEW_PARTNER_A_VOWS:
      return couple1
        ? `Review ${capitalizeFirstLetter(couple1?.preferred_name)}'s Vows`
        : '';
    case ChecklistItemIdentifier.REVIEW_PARTNER_B_VOWS:
      return couple2
        ? `Review ${capitalizeFirstLetter(couple2?.preferred_name)}'s Vows`
        : '';
    case ChecklistItemIdentifier.REVIEW_SCRIPT_WITH_COUPLE:
      return 'Review the Ceremony Script with your couple';
    case ChecklistItemIdentifier.ORDAINED:
      return ordained ? 'Your All-In-One Officiant Package' :'Get ordained instantly';
    //WEDDING PLANNER
    case ChecklistItemIdentifier.PLANNER_PORTAL_DEMO:
      return '1. Watch a two minute Planner Portal demo';
    case ChecklistItemIdentifier.INVITE_FIRST_CLIENT:
      return '2. Invite your first client';
    case ChecklistItemIdentifier.REVIEW_SPEECHES:
      return "3. Review and print your first clients' ceremony and speeches";
    case ChecklistItemIdentifier.INVITE_TOAST_GIVERS:
      return "4. Invite your first client's toast givers";
    case ChecklistItemIdentifier.EXPLORE_RESOURCES:
      return '5. Explore resources to help you win more clients';
    case ChecklistItemIdentifier.ADD_REST_OF_CLIENTS:
      return '6. Add the rest of your current and future clients';
    case ChecklistItemIdentifier.UNIQUE_LINK:
      return '7. Use our template to invite your clients with a unique link';
    case ChecklistItemIdentifier.REFER_CLIENTS:
      return '8. Refer peers for $50 Amazon Gift Cards';
    //GUEST
    case ChecklistItemIdentifier.WRITE_TOAST:
      return 'Write your toast';
    case ChecklistItemIdentifier.GUEST_GET_SPEECHWRITING_HELP:
      return 'Work with a Pro Wedding Writer to perfect your toast';
    case ChecklistItemIdentifier.GUEST_GET_PUBLIC_SPEAKING_HELP:
      return 'Get expert public speaking tips';
    case ChecklistItemIdentifier.REVIEW_TOAST_WITH_COUPLE:
      return 'Review toast with couple';
    case ChecklistItemIdentifier.DOWNLOAD_TOAST:
      return 'Download and print your toast';
    case ChecklistItemIdentifier.GUEST_REFER_FRIENDS:
      return 'Refer friends and get $100 in Amazon Gift Cards';
    case ChecklistItemIdentifier.GUEST_WATCH_DEMO:
      return 'Watch a demo of other Provenance products';
    case ChecklistItemIdentifier.GUEST_GIVE_FEEDBACK:
      return 'Tell us how we did';

    default:
      return '';
  }
};

import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Member, MemberType } from '../../../../api/ceremony';
import { interpolatePlaceholders } from '../../../../helpers/placeholderHelper';
import { useDashboard } from '../../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../../provider/moduleAccessProvider';
import { usePaywallModal } from '../../../../provider/paywallModalProvider';
import { DropdownQuestionProvider } from '../../../../provider/questionProviders/dropdownQuestionProvider';
import { OptionsQuestionProvider } from '../../../../provider/questionProviders/optionsQuestionProvider';
import { TextQuestionProvider } from '../../../../provider/questionProviders/textQuestionProvider';
import {
  Question,
  QuestionOptions,
} from '../../../Modules/common/moduleQuestion/ModuleQuestion';
import {
  QuestionBanner,
  QuestionBannerProps,
} from '../../../Modules/common/moduleQuestion/QuestionBanner';
import { parseSubtitle } from '../../helpers/parsers';

import { ModuleQuestionHeader } from './ModuleQuestionHeader';
import { QuestionDropdown } from './QuestionTypes/QuestionDropdown';
import { QuestionSelect } from './QuestionTypes/QuestionSelect/QuestionSelect';
import { QuestionText } from './QuestionTypes/QuestionText';

export enum QuestionType {
  Select = 'select',
  Dropdown = 'dropdown',
  Options = 'options',
  Declaration = 'declaration',
  Pronouncement = 'pronouncement',
  Text = 'text',
}

export const ModuleQuestion = memo(
  (props: {
    ceremonyId: number;
    question: Question;
    questionNumber: number;
    numberOfQuestions: number;
    members: {
      couple1: Member | undefined;
      couple2: Member | undefined;
      officiant: Member | undefined;
      currentUser: Member | undefined;
    };
    options?: QuestionOptions[];
    onChange?: () => void;
    onQuestionSaved?: () => void;
    required?: boolean;
    label?: string;
    renderFooter?: () => React.ReactNode;
    shouldRerender?: boolean;
    banner?: QuestionBannerProps;
  }) => {
    const {
      question,
      questionNumber,
      numberOfQuestions,
      ceremonyId,
      members,
      options,
      onChange,
      onQuestionSaved,
      required,
      label,
      renderFooter,
      banner,
    } = props;

    const navigate = useNavigate();
    const {
      officiant,
      setMemberType,
      setMemberForEditing,
      setAddModalOpen,
      setOrdainedInfoModalOpen,
    } = useDashboard();

    const manageDropdownInitalValues = (question: Question) => {
      if (
        question.answers &&
        question.answers.length > 0 &&
        question.answers[0].option_id &&
        question.answers[0].option_id.includes(',')
      ) {
        return question.answers[0].option_id.split(',');
      }

      if (question.answers && question.answers.length > 0) {
        return question.answers[0].option_id
          ? [question.answers[0].option_id]
          : [];
      }
      return [];
    };

    const getQuestionInfoText = (question: Question, currentUser?: Member) => {
      let result = question.info_text || '';
      if (question.identifier === 'pronouncementBeWorded') {
        if (currentUser && currentUser.member_type === MemberType.officiant) {
          result =
            'Make sure you are legally ordained by registering for free with our partner, The Provenance Center';
        } else {
          result =
            'Make sure your officiant is legally ordained by inviting yours today to finalize their ordination with our partner, The Provenance Center';
        }
      }
      return result;
    };

    const getQuestionInfoTextClick = (question: Question) => {
      if (question.identifier === 'pronouncementBeWorded') {
        if (
          members.currentUser &&
          members.currentUser.member_type === MemberType.officiant
        ) {
          setOrdainedInfoModalOpen(true);
        } else {
          setMemberType(MemberType.officiant);
          setMemberForEditing(officiant);
          setAddModalOpen(true);
        }
        navigate(`/ceremony/${ceremonyId}/checklist`);
      } else {
        return undefined;
      }
    };

    return (
      <div
        className={`flex flex-col gap-6 sm:gap-8 ${banner ? '' : 'pb-[60px]'}`}
      >
        <ModuleQuestionHeader
          type={question.type as QuestionType}
          questionNumber={questionNumber}
          totalOfQuestions={numberOfQuestions}
          question={interpolatePlaceholders({
            text: question.question,
            data: members,
          })}
          hintText={question.help_short_text}
          subtitle={parseSubtitle(question)}
          infoText={getQuestionInfoText(question, members.currentUser)}
          onInfoTextClick={() => getQuestionInfoTextClick(question)}
          required={required}
        />
        <div className='w-full'>
          {question.type === QuestionType.Text && (
            <TextQuestionProvider question={question} ceremonyId={ceremonyId}>
              <QuestionText
                type={QuestionType.Text}
                value={
                  question.answers && question.answers.length > 0
                    ? question.answers[0].text_answer
                    : ''
                }
                onChange={onChange}
                placeholder={question.textfield_placeholder}
                label={label}
                suggestedMinWords={question.suggested_min_words}
                renderFooter={renderFooter}
                shouldRerender={true}
              />
            </TextQuestionProvider>
          )}
          {(question.type === QuestionType.Options ||
            question.type === QuestionType.Declaration ||
            question.type === QuestionType.Pronouncement) && (
            <OptionsQuestionProvider
              question={question}
              ceremonyId={ceremonyId}
              onChange={onChange}
            >
              <QuestionSelect
                textValueInitial={
                  question.answers && question.answers.length > 0
                    ? question.answers[0].text_answer
                    : ''
                }
                selectedOptionIdsInitial={manageDropdownInitalValues(question)}
                onChange={onChange}
                placeholder={question.textfield_placeholder}
                label={''}
                suggestedMinWords={question.suggested_min_words}
                options={options || question.options || []}
                question={question}
                members={members}
                ceremonyId={ceremonyId}
                renderFooter={renderFooter}
                useHtmlDescription={true}
              />
            </OptionsQuestionProvider>
          )}
          {question.type === QuestionType.Dropdown && (
            <DropdownQuestionProvider
              question={question}
              ceremonyId={ceremonyId}
              onChange={() => {
                onChange?.();
                onQuestionSaved?.();
              }}
            >
              <QuestionDropdown
                question={question}
                members={members}
                selectedOptionIdsInitial={manageDropdownInitalValues(question)}
                renderFooter={renderFooter}
              />
            </DropdownQuestionProvider>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.shouldRerender || nextProps.question !== prevProps.question) {
      return false;
    }
    return true;
  }
);

import { MemberType } from '../api/ceremony';

type TextsObj = {
  [key: string]: {
    default: string; // default text
    dynamicText?: boolean; // if true, then the text is dynamic. use {{keyWord}} to replace the keyWord with the value
    memberTypes: {
      [key in MemberType]?: string; // if the text is different for different member types, then add the text here.]:
    };
  };
};

const textsObj: TextsObj = {
  checklist_0_subtitle: {
    default:
      'No one gets married alone. Ensure those helping you make this day special are invited into Provenance to collaboratively design your Wedding. Provenance’s tools are best when used together.',
    memberTypes: {
      officiant:
        'No one gets married alone. Ensure those helping you make this day special are invited into Provenance to collaboratively design their Wedding. Provenance’s tools are best when used together.',
    },
  },
  checklist_1_title: {
    default: 'Craft Your Ceremony',
    memberTypes: {
      officiant: 'Craft Their Ceremony',
    },
  },
  checklist_2_title: {
    default: 'Write your vows',
    memberTypes: {
      officiant: 'Review their vows and help ensure they’re balanced',
    },
  },
  checklist_3_subtitle: {
    default:
      'Give your wedding speakers the tools to feel confident about their toasts (while staying within your requested time limit).',
    memberTypes: {
      officiant:
        'Give their wedding speakers the tools to feel confident about their toasts (while staying within the couple’s requested time limit).',
    },
  },
  checklist_more_element_1_title: {
    default: 'Change Your Name Without the Hassle',
    memberTypes: {
      //officiant: "Find out how they can get the marriage license 100% online",
    },
  },
  checklist_more_element_2_title: {
    default: 'Submit Your Marriage License Online',
    memberTypes: {
      //officiant: "Find out how they can get the marriage license 100% online",
    },
  },
  checklist_more_element_3_title: {
    default: 'Get a State-Specific Prenuptal Agreement',
    memberTypes: {
      //officiant: "Find out how they can get the marriage license 100% online",
    },
  },
  checklist_work_with_speech_writer: {
    default: 'Work with a Speech Writer to perfect your ceremony or vows',
    memberTypes: {
      officiant: 'Work with a Speech Writer to perfect their ceremony',
    },
  },
  checklist_download_expert_guide: {
    default: 'Download expert guide to your processional order',
    memberTypes: {
      officiant: 'Download expert guide to the processional order',
    },
  },
  set_wedding_date: {
    default: 'Set your wedding date',
    memberTypes: {
      officiant: 'Set the wedding date',
    },
  },
  share_love_story: {
    default: 'Share your love story',
    memberTypes: {
      officiant: 'Collect the details of their Love Story',
    },
  },
  ceremony_builder_subheading: {
    default:
      'Answer the questions in each chapter below, then watch as your responses get woven together into a draft that you, your partner, and your officiant can edit until your big day',

    memberTypes: {
      officiant:
        'Have the couple answer the questions in each chapter below, then watch as their responses get woven together into a draft that you and the couple can edit until the big day',
    },
  },
  ceremony_details_description: {
    default:
      'Set the stage for your dream ceremony. Choose the length, tone, special acknowledgments and more.',
    memberTypes: {
      officiant:
        'Set the stage for their dream ceremony. Choose the length, tone, special acknowledgments and more.',
    },
  },
  readings_rituals_description: {
    default:
      'Explore a wide range of religious, non-religious, and cultural readings and rituals to create an authentic ceremony that reflects your relationship and honors your history, your way.',
    memberTypes: {
      officiant:
        'Explore a wide range of religious, non-religious, and cultural readings and rituals to create an authentic ceremony that reflects their relationship and honors their history, their way.',
    },
  },
  officiants_address_description: {
    default:
      'Bring your love story to life. Answer curated questions that showcase your journey together and ensure your ceremony will be uniquely yours.',
    memberTypes: {
      officiant:
        'Bring their love story to life. Answer curated questions that showcase their journey together and ensure your ceremony will be uniquely theirs.',
    },
  },
  love_story_about: {
    default:
      'To design a uniquely personal ceremony script, it is critical to surface your stories and insights so the officiant can weave your voice into their remarks. The questions below, curated by experienced officiants, will ensure that the ceremony is grounded in your authentic truth. The officiant can interview the couple together or individually and transcribe their answers below, or the couple can answer these questions independently.',
    memberTypes: {
      officiant:
        'To design a uniquely personal ceremony script, it is critical to surface the stories and insights so the officiant can weave the couple`s voice into their remarks. The questions below, curated by experienced officiants, will ensure that the ceremony is grounded in their authentic truth. The officiant can interview the couple together or individually and transcribe their answers below, or the couple can answer these questions independently.',
    },
  },
  i_dos_description: {
    default:
      'Select the specific words to be shared during the wedding ceremony’s grand finale! These statements officially declare your love and commitment and may be legally required (depending on location).',
    memberTypes: {
      officiant:
        'Choose the specific words to be shared during the wedding ceremony’s grand finale! These statements officially declare the couple`s love and commitment and may be legally required in certain states.',
    },
  },
  i_dos_about: {
    default:
      'The “Declaration of Intent” (the ring exchange) and the “Pronouncement” (the grand finale) serve as both the spiritual and (often) legal bind, uttered in front of the audience to declare your commitment to one another. While these words often follow a typical structure, you should align on what specific phrasing you’d prefer.',
    memberTypes: {
      officiant:
        'The “Declaration of Intent” (the ring exchange) and the “Pronouncement” (the grand finale) serve as both the spiritual and (often) legal bind, uttered in front of the audience to declare each partner’s commitment to one another. While these words often follow a typical structure, the specific phrasing can be customized.',
    },
  },

  toast_builder_description: {
    default:
      'Write your welcome toast below. You can also add guest speakers so they know when they are speaking, how long their toast should be, and any topics to focus on (or steer clear from!). These speakers will get access to our easy-to-use Toast Builder.',
    memberTypes: {
      officiant:
        'Invite anyone speaking at the wedding so they know how long to speak for and any critical topics to lean into (or steer clear away from).  The invitees will also get access to our easy-to-use, stress-free Toast Builder.',
    },
  },
  toast_builder_empty_state_text: {
    default:
      'Add and manage individuals who are giving speeches/toasts at your wedding.',
    memberTypes: {
      officiant:
        'Add individuals who are giving toasts at your couple’s wedding.',
    },
  },
  not_sure_yet: {
    default: "We're not sure yet",
    memberTypes: {
      officiant: "They're not sure yet",
    },
  },
  invite_couple_1: {
    default: 'Invite your partner',
    memberTypes: {
      officiant: 'Invite {{couple1Name}}',
    },
    dynamicText: true,
  },
  invite_couple_2: {
    default: 'Invite your partner',
    memberTypes: {
      officiant: 'Invite {{couple2Name}}',
    },
    dynamicText: true,
  },
  vow_builder_description: {
    default:
      'Share your favorite stories, memories, and moments of love, and we’ll help generate a first draft',
    memberTypes: {
      officiant:
        'Each partner can use the Vow Builder to share their favorite stories, memories, and moments of love, and then watch as their words are organized into well-structured vows that flow with ease. If the couple wants you to review their vows, they will need to grant you access under their “privacy settings”',
    },
  },
  vow_item_both_shared_1: {
    default:
      "View {{couple1Name}}'s work-in-progress vows here to get a sense of what they will say on the big day.",
    memberTypes: {
      officiant:
        '{{couple1Name}} shared their vows with you. Before proceeding, ask if they want feedback or assistance with printing their vows. Since both partners have shared their vows, ask if they would like to receive feedback on the balance of length and tone.',
    },
    dynamicText: true,
  },
  vow_item_both_shared_2: {
    default:
      "View {{couple2Name}}'s work-in-progress vows here to get a sense of what they will say on the big day.",
    memberTypes: {
      officiant:
        '{{couple2Name}} shared their vows with you. Before proceeding, ask if they want feedback or assistance with printing their vows. Since both partners have shared their vows, ask if they would like to receive feedback on the balance of length and tone.',
    },
    dynamicText: true,
  },
  vow_item_I_shared_1: {
    default:
      "View {{couple1Name}}'s work-in-progress vows here to get a sense of what they will say on the big day.",
    memberTypes: {
      officiant:
        '{{couple2Name}} has not yet shared their vows with you. Invite them to use the Vow Builder by clicking the green button, and ask them if they want to give you access (which they can do through the Privacy Settings).',
    },
    dynamicText: true,
  },
  vow_item_I_shared_2: {
    default:
      "View {{couple2Name}}'s work-in-progress vows here to get a sense of what they will say on the big day.",
    memberTypes: {
      officiant:
        '{{couple1Name}} has not yet shared their vows with you. Invite them to use the Vow Builder by clicking the green button, and ask them if they want to give you access (which they can do through the Privacy Settings).',
    },
    dynamicText: true,
  },
  tour_modal_text: {
    default:
      "We're excited you're here. Whether you're crafting a personalized wedding ceremony, heartfelt vows, or a thoughtful toast, we'll guide you every step of the way.",
    memberTypes: {
      officiant:
        "We're excited you're here. Whether you're crafting a personalized wedding ceremony, helping the couple with their heartfelt vows, or writing a thoughtful toast, we'll guide you every step of the way.",
      wedding_planner:
        "We're excited you're here. By providing Provenance's tools to your client(s), you ensure their ceremonies, vows, and toasts are as beautiful as the rest of the event.",
    },
  },
  refer_friends_button_text: {
    default: 'Get $100 Amazon Gift Card',
    memberTypes: {
      wedding_planner: 'Become an Affiliate',
     
    }
  },
  refer_friends_button_text_for_guests_purchased: {
    default: 'Get $100 Amazon Gift Card',
    memberTypes: {}
  },
  refer_friend_modal_thank_you_text: {
    default: 'Thank you for referring your friends. You will receive a $10 Amazon gift card for every referral who upgrades to a paid plan, up to $100 in Amazon credit.',
    memberTypes: {
      wedding_planner: 'Thank you for becoming an affiliate. You will receive a $10 Amazon gift card for every referral who upgrades to a paid plan, up to $100 in Amazon credit.',
      guest: 'Thank you for referring your friends. You will receive Toast Builder free once they sign up for Provenance.'
    }
  },
  refer_friend_modal_title: {
    default: '$20 for you. 20% off for them.',
    memberTypes: { }
  },
  refer_friend_modal_subtitle: {
    default: "Share your referral link with friends who are planning weddings, officiating, or giving toasts. You’ll earn a $20 Amazon gift card for each upgrade, and they’ll enjoy 20% off their purchase.",
    memberTypes: { }
  },
  refer_friend_modal_email_button_text: {
    default: 'Email your link',
    memberTypes: { }
  },
  invite_collaborators: {
    default: 'Invite your Partner, Wedding Planner, and others',
    memberTypes: {
      officiant: 'Invite the Couple, their Wedding Planner, and others',
    },
  },
  checklist_subtitle: {
    default:
      'Your go-to checklist for crafting personalized, heartfelt moments for your wedding, whether it`s for the ceremony, vows, toasts, or beyond.',
    memberTypes: {
      officiant:
       'Your go-to checklist for crafting personalized, heartfelt moments for the wedding, whether it`s for the ceremony, vows, toasts, or beyond.',
    },
  },
  vows_module_description: {
    default:
      'Whether sharing vows in private or in front of guests, answer these quick questions to inform the ceremony script. Then, use the Vow Builder to get started.',
    memberTypes: {
      officiant:
        'Whether sharing vows in private or in front of guests, have the couple answer these quick questions to inform the ceremony script.',
    },
  },
  doing_personalized_vows: {
    default: 'Doing personalized vows? Check out our Vow Builder from your checklist.',
    memberTypes: {
      officiant: 'Invite the couple from your collaborator`s section to give them access to the Vow Builder for free from their checklist.',
    },
  },
  invite_partner: {
    default: 'Invite Partner',
    memberTypes: {
      officiant: 'Invite Couple',
    },
  }
};

export default textsObj;

import { NavigateFunction } from 'react-router-dom';

import { Ceremony, Member } from '../../api/ceremony';
import {
  ChecklistItemDefinition,
  MemberChecklistItem,
} from '../../api/checklistItemDefinitions';
import { Order } from '../../api/paywall';
import { Module } from '../../hooks/useActiveModule';
import { GroupChecklistItem } from '../../pages/Ceremony/CeremonyChecklist/v2/ChecklistGroup';

import { shouldShowGem } from './gemHelper';
import {
  getIconBackgroundByIdentifier,
  getIconSrcByIdentifier,
} from './iconHelper';
import { ChecklistItemIdentifier } from './identifiers';
import { getOnAfterCompletedClickHandler } from './onAfterCompletedClickHelper';
import { getMainOnClickHandler } from './onMainClickHelper';
import { getMarkCompleteOnClickHandler } from './onMarkCompleteClickHelper';
import { getSkipTaskOnClickHandler } from './onSkipTaskClickHelper';
import { getWatchDemoOnClickHandler } from './onWatchDemoClickHelper';
import { didPayForOrdination, getOrdainedButtonText } from './ordainedHelper';
import { getChecklistItemStatusFromDbStatus } from './statusHelper';
import { getTitleByIdentifier } from './titleHelper';

export const getGroupChecklistItem = (
  item: ChecklistItemDefinition,
  navigate: NavigateFunction,
  checkAccessToModule: (module: Module) => boolean,
  saveMemberChecklistItem: (
    memberChecklistItem: MemberChecklistItem
  ) => Promise<void>,
  setChooseCollaboratorModalOpen?: (value: boolean) => void,
  setGuestSpeakerModalOpen?: (value: React.SetStateAction<boolean>) => void,
  handleShowNPSModal?: () => void,
  ceremonyId?: number,
  currentUser?: Member,
  couple1?: Member,
  couple2?: Member,
  userCeremonies?: Ceremony[],
  orders?: Order[],
  ordained?: boolean
) => {


  if (
    item.identifier === ChecklistItemIdentifier.REVIEW_PARTNER_A_VOWS &&
    couple1 &&
    !couple1.share_vows_content_with_officiant
  ) {
    return null;
  }
  if (
    item.identifier === ChecklistItemIdentifier.REVIEW_PARTNER_B_VOWS &&
    couple2 &&
    !couple2.share_vows_content_with_officiant
  ) {
    return null;
  }
  const result: GroupChecklistItem = {
    onClick: getMainOnClickHandler(
      item,
      navigate,
      checkAccessToModule,
      saveMemberChecklistItem,
      setGuestSpeakerModalOpen ? setGuestSpeakerModalOpen : () => {},
      handleShowNPSModal ? handleShowNPSModal : () => {},
      setChooseCollaboratorModalOpen
        ? setChooseCollaboratorModalOpen
        : () => {},
      ceremonyId,
      currentUser,
      couple1,
      couple2,
      userCeremonies,
      ordained
    ),
    onSkipTaskClick: getSkipTaskOnClickHandler(
      item,
      saveMemberChecklistItem,
      ceremonyId,
      currentUser,
      ordained
    ),
    onWatchDemoClick: getWatchDemoOnClickHandler(
      item,
      ceremonyId,
      currentUser,
      saveMemberChecklistItem
    ),
    onMarkCompleteClick: getMarkCompleteOnClickHandler(
      item,
      saveMemberChecklistItem,
      ceremonyId,
      currentUser
    ),
    onAfterCompletedClick: getOnAfterCompletedClickHandler({
      item,
      navigate,
      ceremonyId,
    }),
    title: getTitleByIdentifier(item.identifier, couple1, couple2, ordained),
    iconBackground: getIconBackgroundByIdentifier(item.identifier),
    iconSrc: getIconSrcByIdentifier(item.identifier),
    status: getChecklistItemStatusFromDbStatus(item.member_checklist_item),
    showGem: shouldShowGem(item, checkAccessToModule),
    ordainedButtonText: orders ? getOrdainedButtonText(orders) : '',
    paidForOrdination: orders ? didPayForOrdination(orders) : false,
    ordained: ordained, 
  };
  return result;
};

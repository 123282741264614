import classNames from 'classnames';
import ReactFbPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

import { generalLog } from '../../api/ceremony';
import {
  CURRENCIES,
  getPaymentOptions,
  NewPaymentBody,
  newpaymentSession,
  OrderType,
  PaymentMethod,
  PayOption,
  PayOptionsResponse,
  PayOrderBody,
  PayOrderResponse,
  placeNewOrder,
} from '../../api/paywall';
import arrowLeftSvg from '../../assets/images/icons/arrow-left-circle.svg';
import ForestCheckmark from '../../assets/images/svg/forest-checkmark.svg';
import Info from '../../assets/images/svg/info.svg';
import PlanIcon1 from '../../assets/images/svg/plan1.svg';
import PlanIcon2 from '../../assets/images/svg/plan2.svg';
import PlanIcon3 from '../../assets/images/svg/plan3.svg';
import Quotes from '../../assets/images/svg/quotes.svg';
import TestimonialImg from '../../assets/images/svg/testimonialImg.svg';
import Container from '../../components/Container';
import Loader from '../../components/Loader/loader';
import SnackbarComponent from '../../components/Snackbar/Snackbar';
import { Title } from '../../components/titles';
import ButtonPill from '../../components/v2/ButtonPill';
import { Modal } from '../../components/v2/Modal/Modal';
import { getAPIErrorMessage } from '../../helpers/helper';
import useCeremony from '../../hooks/useCeremony';
import useLoader from '../../hooks/useLoader';
import { useSnackbar } from '../../hooks/useSnackbar';

import CloseSvg from './images/close.svg';
import Gift from './images/gift.svg';
import './index.scss';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { SelectField } from '../../components';
import InfoIcon from '../../components/v2/InfoIcon/InfoIcon';
import { Typography } from '../../components/v2/Typography/Typography';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import CampaignItem from '../../components/v2/CampaignItem/CampaignItem';
import { _sendMetaEvent } from '../../helpers/metaHelper';
import useUser from '../../hooks/useUser';
import { usePaywall } from '../../provider/paywallProvider';
import { Addon, getAddons } from '../../api/addons';

import UpsellModal from './modals/UpsellModal';

import { useDashboard } from '../../provider/dashboardProvider';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { fireAddToCartEvents } from '../../helpers/paywallHelper';

type PayPlansListProps = {
  title: string;
  subtitle?: string;
  elevio?: string;
  negative?: boolean;
  positive?: boolean;
  className?: string;
  small?: boolean;
};
export const PayPlansList = (props: PayPlansListProps) => {
  return (
    <div
      className={classNames(
        [props.small ? 'pt-0' : 'pt-2'],
        `pl-2 flex flex-row py-2 ${props.negative ? 'bg-[#9943301A]' : ''} ${
          props.positive ? 'bg-[#346B4D1A]' : ''
        } ${!props.title ? 'hidden' : ''}`
      )}
    >
      <h6 className='font-recife text-forest-primary'>
        <div className={classNames(['flex', props.className])}>
          <img
            src={props.negative ? CloseSvg : ForestCheckmark}
            alt={'check mark'}
            className={classNames([
              `self-start ${
                props.negative ? 'mt-0' : props.small ? '' : 'mt-1'
              }`,
              props.small ? 'w-3 self-center mr-2' : 'pr-5',
            ])}
          />

          {!props.small && (
            <Typography variant='primary' type='body-600'>
              {props.title}
            </Typography>
          )}

          {props.small && (
            <Typography variant='functional-low' type='body-200'>
              {props.title}
            </Typography>
          )}

          {props.elevio && (
            <img
              src={Info}
              alt={'info'}
              className={`pl-2 cursor-pointer ${props.elevio}`}
            />
          )}
        </div>
        {props.subtitle && (
          <p className='font-recife text-sm text-forest-300 pl-12 font-light'>
            {props.subtitle.split(',').map((item, index) => {
              return (
                <li className='pt-2' key={index}>
                  {item}
                </li>
              );
            })}
          </p>
        )}
      </h6>
    </div>
  );
};

const PayPlans = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { fetchCeremonyPlanById, ceremonyPlan } = useCeremony();
  const [learnMoreOpen, setLearnModeOpen] = useState(false);
  const { isActive, message, type, openSnackBar, handleClose } = useSnackbar();
  const [payPlans, setPayPlans] = useState<PayOption[]>([]);
  const [payPlansSet, setPayPlansSet] = useState<boolean>(false);
  const [elevioArticleId, setElevioArticleId] = useState<number>(0);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [hidePlan, setHidePlan] = useState<string>('');
  const [filteredPlans, setFilteredPlans] = useState<PayOption[]>([]);
  //get url params
  const urlParams = new URLSearchParams(window.location.search);
  const refferedFrom = urlParams.get('refferedFrom');
  const [showCouponCodeBanner, setShowCouponCodeBanner] =
    useState<boolean>(false);
  const { source } = usePaywall();
  const [isAddonModalOpen, setAddonModalOpen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<PayOption | null>(null);
  const [addons, setAddons] = useState<Addon[]>([]);
  const [isUpsellModalOpen, setUpsellModalOpen] = useState(false);
  const [skipUpsell, setSkipUpsell] = useState(false);
  const [upsoldPlan, setUpsoldPlan] = useState<PayOption | null>(null);
  const [fetchingPlans, setFetchingPlans] = useState<boolean>(false);

  const [frontendPlansData, setFrontendPlansData] = useState<{
    [key: string]: {
      title: string;
      description: string;
      icon: string;
      is_featured: boolean;
      hidden: boolean;
      attributes: {
        title: string;
        elevio: string;
        negative?: boolean;
        positive?: boolean;
      }[];
    };
  }>({
    'Provenance Premium': {
      title: 'Premium Bundle',
      description:
        "Full access to Provenance's tools for crafting your personalized ceremony, vows, and toasts.",
      icon: Gift,
      is_featured: true,
      hidden: false,
      attributes: [
        {
          title: 'Ceremony Builder',
          elevio: 'ceremony-benefit',
        },
        {
          title: 'Vow Builder',
          elevio: 'vow-benefit',
        },
        {
          title: 'Toast Builder',
          elevio: 'toast-benefit',
        },
        {
          title: 'Wedding Planner Portal',
          elevio: 'planner-benefit',
        },
        {
          title: 'Prioritized Customer Support',
          elevio: '',
        },
      ],
    },
    Ceremony: {
      title: 'Ceremony',
      description:
        'Craft a customized ceremony script from processional to officiant speech to readings to "I Do."',

      icon: PlanIcon2,
      is_featured: false,
      hidden: hidePlan === 'Ceremony',
      attributes: [
        {
          title: 'Ceremony Builder',
          elevio: 'ceremony-benefit',
        },
        {
          title: 'Toast Builder',
          elevio: 'toast-benefit',
        },
        {
          title: 'Wedding Planner Portal',
          elevio: 'planner-benefit',
        },
      ],
    },
    Vows: {
      title: 'Vows',
      description:
        "Generate a first draft of your heartfelt vows while ensuring your vows balance your partner's in length and tone.",
      icon: PlanIcon3,
      is_featured: false,
      hidden: hidePlan === 'Vows',
      attributes: [
        {
          title: 'Vow Builder',
          elevio: 'vow-benefit',
        },
        {
          title: 'Toast Builder',
          elevio: 'toast-benefit',
        },
        {
          title: 'Wedding Planner Portal',
          elevio: 'planner-benefit',
        },
      ],
    },
    'Provenance Premium Speech Writing': {
      title: 'Provenance Platinum',
      description:
        "We'll match you with a Professional Speechwriter to polish your ceremony script, vows or toast draft.",
      icon: PlanIcon1,
      is_featured: false,
      hidden: false,
      attributes: [
        {
          title: 'Everything in Premium Bundle',
          elevio: '',
        },
        {
          title:
            'Receive Comments, Edits and Suggestions from a Professional Speechwriter',
          elevio: '',
        },
      ],
    },
  });

  const { user } = useUser();

  const { fetchCeremony } = useDashboard();

  const icons = {
    Vows: PlanIcon2,
    'The Modern Wedding': Gift,
    'The Grand Wedding': PlanIcon1,
  };

  useEffect(() => {
    fetchCeremony(params.ceremonyid || '');
  }, []);

  useEffect(() => {
    if (filteredPlans) {
      setPayPlans([...filteredPlans]);
    }
  }, [filteredPlans]);

  useEffect(() => {
    ceremonyPlan && setPayPlansSet(true);
  }, [payPlans, ceremonyPlan]);

  // useEffect(() => {
  //   payPlansSet && ifUpgradeShowOnlyLastPlan();
  // }, [payPlansSet]);

  const CURRENCIES_SYMBOLS = {
    USD: '$',
    CAD: 'CA$',
    AUD: 'AU$',
    NZD: 'NZ$',
    GBP: '£',
  };
  const CURRENCIES = ['USD', 'CAD', 'AUD', 'NZD', 'GBP'];
  const COUNTRIES_CURRENCIES: CURRENCIES = {
    CA: 'CAD',
    AU: 'AUD',
    NZ: 'NZD',
    GB: 'GBP',
    US: 'USD',
  };

  useEffect(() => {
    document.title = 'Provenance App – Pay Plans';

    window.gtag('event', 'conversion', {
      send_to: 'AW-11021677486/knAKCMmh6JoYEK7nxYcp',
    });
  });

  useEffect(() => {
    if (refferedFrom) {
      if (
        refferedFrom === 'The “I Do’s”' ||
        refferedFrom === 'Love Story' ||
        refferedFrom === 'CeremonyBuilderBanner' ||
        refferedFrom === 'CeremonyDetails'
      ) {
        setHidePlan('Vows');
      } else if (refferedFrom === 'vows' || refferedFrom === 'openVows') {
        setHidePlan('Ceremony');
      } else if (refferedFrom === 'campaign') {
        setHidePlan('');
      }
    }
  }, [refferedFrom]);

  useEffect(() => {
    setSelectedCurrency('');
  }, [user]);

  useEffect(() => {
    if (user && user.country_code) {
      const currency = COUNTRIES_CURRENCIES[user.country_code];
      if (currency) {
        setSelectedCurrency(currency);
      } else {
        setSelectedCurrency('USD');
      }
    } else {
      setSelectedCurrency('USD');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrency]);

  useEffect(() => {
    if (params.ceremonyid) {
      void getPlans(params.ceremonyid);
      void sendCustomerIoEventHandler('Payment Process Started', {});
    }
  }, [params.ceremonyid]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchCeremonyPlanById(params.ceremonyid || '');
    _getAddons();
  }, []);

  const getPlans = async (id: string) => {
    if (fetchingPlans) {
      return;
    }

    setFetchingPlans(true);

    showLoader();
    try {
      const optionsResponse: PayOptionsResponse = await getPaymentOptions(
        params.ceremonyid || ''
      );
      if (optionsResponse.success) {
        let sortedPlans = optionsResponse.data.sort((a, b) => {
          return a.price - b.price;
        });

        if (selectedCurrency !== 'USD') {
          sortedPlans = sortedPlans.map((plan) => {
            return {
              ...plan,
              price:
                optionsResponse.conversionRates?.find(
                  (rate) =>
                    COUNTRIES_CURRENCIES[rate.conversion_to_country_code] ===
                      selectedCurrency &&
                    rate.conversion_from_value === plan.price
                )?.conversion_to_value || plan.price,
            };
          });
        }

        //if plan title is Provenance Premium Speech Writing, move it to the end
        sortedPlans = sortedPlans.sort((a, b) => {
          if (a.name === 'Provenance Premium Speech Writing') {
            return 1;
          } else if (b.name === 'Provenance Premium Speech Writing') {
            return -1;
          } else {
            return 0;
          }
        });

        if (sortedPlans[0].version === 2) {
          let hiddenPlanId: number | undefined;

          if (
            refferedFrom !== 'vows' &&
            refferedFrom !== 'vows_cb' &&
            refferedFrom !== 'campaign' &&
            refferedFrom !== 'openVows'
          ) {
            hiddenPlanId = sortedPlans.find((plan) => plan.name === 'Vows')?.id;
          }

          setFrontendPlansData(
            sortedPlans.reduce((acc, plan) => {
              return {
                ...acc,
                [plan.name]: {
                  title: plan.name,
                  description: plan.description,
                  icon: icons[plan.name as keyof typeof icons],
                  is_featured: plan.is_popular,
                  hidden: plan.id === hiddenPlanId,
                  attributes: [
                    // (refferedFrom === 'vows' || refferedFrom === 'vows_cb') &&
                    //   plan.name === 'The Grand Wedding' && {
                    //     title: 'Everything in Vows',
                    //     elevio: '',
                    //   },
                    ...plan.features.map((feature) => {
                      if (
                        refferedFrom !== 'vows' &&
                        refferedFrom !== 'vows_cb' &&
                        refferedFrom !== 'openVows' &&
                        feature.title === 'Everything in Vows' &&
                        plan.name === 'The Modern Wedding'
                      ) {
                        return {
                          title: 'Unlimited Vow Draft Generations',
                        };
                      }

                      if (
                        refferedFrom === 'toast' &&
                        plan.name === 'The Grand Wedding' &&
                        feature.title === 'Unlimited Guest Toast Invites'
                      ) {
                        return {
                          title: feature.title,
                          elevio: '',
                          positive: true,
                        };
                      } else if (
                        refferedFrom === 'weddingPlanner' &&
                        plan.name === 'The Grand Wedding' &&
                        feature.title === 'Wedding Planner Access'
                      ) {
                        return {
                          title: feature.title,
                          elevio: '',
                          positive: true,
                        };
                      } else {
                        return {
                          title: feature.title,
                          elevio: '',
                        };
                      }
                    }),
                    refferedFrom === 'toast' &&
                      plan.name === 'The Modern Wedding' && {
                        title: 'No Guest Toast Invites',
                        negative: true,
                      },
                    refferedFrom === 'weddingPlanner' &&
                      plan.name === 'The Modern Wedding' && {
                        title: 'No Wedding Planner Access',
                        negative: true,
                      },
                  ],
                },
              };
            }, {})
          );
        }

        if (sortedPlans[0].version >= 3) {
          let hiddenPlanId: number | undefined;

          if (
            refferedFrom !== 'vows' &&
            refferedFrom !== 'openVows' &&
            refferedFrom !== 'vows_cb' &&
            refferedFrom !== 'campaign' &&
            refferedFrom !== 'weddingPlanner' &&
            refferedFrom !== 'guests' &&
            refferedFrom
          ) {
            hiddenPlanId = sortedPlans.find((plan) => plan.name === 'Vows')?.id;
          }

          setFrontendPlansData(
            sortedPlans.reduce((acc, plan) => {
              //This adds the Expert Guide to Processional Order feature
              // to The Grand Wedding plan before the last feature
              if (plan.name === 'The Grand Wedding') {
                const features = plan.features;
                const index = features.length - 1;
                features.splice(index, 0, {
                  title: 'Expert Guide to Processional Order',
                  id: 0,
                  plan_id: 0,
                  active: true,
                  createdAt: '',
                  updatedAt: '',
                });
              }

              return {
                ...acc,
                [plan.name]: {
                  title: plan.name,
                  description: plan.description,
                  icon: icons[plan.name as keyof typeof icons],
                  is_featured: plan.is_popular,
                  hidden: plan.id === hiddenPlanId,
                  attributes: [
                    ...plan.features.map((feature) => {
                      if (
                        refferedFrom !== 'vows' &&
                        refferedFrom !== 'vows_cb' &&
                        refferedFrom !== 'openVows' &&
                        feature.title === 'Everything in Vows' &&
                        plan.name === 'The Modern Wedding'
                      ) {
                        return {
                          title: 'Unlimited Vow Draft Generations',
                        };
                      }

                      if (
                        refferedFrom === 'toast' &&
                        plan.name === 'The Grand Wedding' &&
                        feature.title === 'Unlimited Welcome Toast Generations'
                      ) {
                        return {
                          title: feature.title,
                          elevio: '',
                          positive: true,
                        };
                      }

                      if (
                        refferedFrom === 'guests' &&
                        plan.name === 'The Modern Wedding' &&
                        feature.title === 'Unlimited Guest Toast Invites'
                      ) {
                        return {
                          title: feature.title,
                          elevio: '',
                          positive: true,
                        };
                      } else if (
                        refferedFrom === 'weddingPlanner' &&
                        plan.name === 'The Modern Wedding' &&
                        feature.title === 'Wedding Planner Access'
                      ) {
                        return {
                          title: feature.title,
                          elevio: '',
                          positive: true,
                        };
                      } else {
                        return {
                          title: feature.title,
                          elevio: '',
                        };
                      }
                    }),
                    refferedFrom === 'guests' &&
                      plan.name === 'Vows' && {
                        title: 'No Guest Toast Invites',
                        negative: true,
                      },
                    refferedFrom === 'weddingPlanner' &&
                      plan.name === 'Vows' && {
                        title: 'No Wedding Planner Access',
                        negative: true,
                      },
                    refferedFrom === 'toast' &&
                      plan.name === 'The Modern Wedding' && {
                        title: 'No Welcome Toast Generations',
                        negative: true,
                      },
                  ],
                },
              };
            }, {})
          );
        }

        setPayPlans([...sortedPlans]);
        setPayPlansSet(true);

        hideLoader();
      } else {
        hideLoader();
        openSnackBar(optionsResponse.message, 5000, 'error');
      }
    } catch (err) {
      hideLoader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      setFetchingPlans(false);
    }
  };

  const _getAddons = async () => {
    try {
      const addonsResponse = await getAddons();
      setAddons(addonsResponse.data.sort((a, b) => a.order - b.order));
    } catch (err) {
      console.log('Could not fetch addons', err);
    }
  };

  const getPlanPrice = (price: number, planId: number) => {
    //find ceremonyplan in payplans
    const ceremonyPlanInPayPlans = payPlans.find(
      (plan) => plan.id === ceremonyPlan?.product_id
    );

    if (price === 0 || ceremonyPlanInPayPlans?.id === planId) {
      return price;
    } else if (ceremonyPlan && ceremonyPlanInPayPlans) {
      return (price - ceremonyPlanInPayPlans.price).toFixed(2);
    } else {
      return price;
    }
  };

  const ifUpgradeShowOnlyLastPlan = () => {
    if (ceremonyPlan && ceremonyPlan.product_id) {
      //get current plan
      const currentPlan = payPlans.find(
        (plan) => plan.id === ceremonyPlan.product_id
      );

      //remobe current plan from the list
      let filteredPlans = [];

      if (currentPlan?.name === 'Vows') {
        //remove plan named Ceremony

        filteredPlans = payPlans.filter(
          (plan) =>
            plan.id !== ceremonyPlan.product_id && plan.name !== 'Ceremony'
        );
      } else {
        //remove plan named Vows
        filteredPlans = payPlans.filter(
          (plan) => plan.name !== 'Vows' && plan.id !== ceremonyPlan.product_id
        );
      }

      setFilteredPlans(filteredPlans);
    }
  };

  const handleAddToCart = (plan: PayOption) => {
    // if (process.env.REACT_APP_DISPLAY_REFLECTIONS !== 'true') {
    //   handlePlaceOrder(plan);
    // } else {
    //   setAddonModalOpen(true);
    //   setSelectedPlan(plan);
    // }

    /**
     * This logic is frail because it uses the payplan names
     * If is not upgrade, no skip upsell, plan version greater than 4 and vowslanding_test_category is modal
     * then show upsell modal
     */
    if (
      !ceremonyPlan &&
      // plan.name !== 'The Grand Wedding' &&
      !skipUpsell &&
      plan.version >= 4 &&
      isUpsellModalOpen === false
    ) {
      setUpsellModalOpen(true);

      setSelectedPlan(plan);
      return;
    }

    void handlePlaceOrder(plan);
  };

  const handlePlaceOrder = async (plan: PayOption | null = null) => {
    if (selectedPlan) {
      plan = selectedPlan;
    }

    if (!plan) {
      return;
    }

    window.gtag('event', 'conversion', {
      send_to: 'AW-11021677486/VJ3gCMah6JoYEK7nxYcp',
      value: plan.price,
      currency: 'USD',
    });

    ReactFbPixel.track('AddToCart');

    TiktokPixel.track('AddToCart', {
      content_type: 'product',
      quantity: 1,
      content_name: plan.name,
      content_id: plan.id,
      currency: 'USD',
      value: plan.price,
    });

    fireAddToCartEvents({
      selectedAddons,
      upsoldPlan,
      plan,
      addons,
    });

    await generalLog({
      ceremony_id: params.ceremonyid ?? 0,
      activity: `Select Paywall Plan - ${plan.name}`,
    });

    void sendCustomerIoEventHandler('Plan Selected', {
      plan_name: plan.name,
      plan_value: plan.price,
    });

    void _sendMetaEvent('AddToCart', {
      product: plan.name,
      price: plan.price,
    });

    const price = Number(getPlanPrice(plan.price, plan.id)) || 0;

    await placeOrder(plan, price);
  };

  const placeOrder = async (plan: PayOption, purchasePrice: number) => {
    showLoader();

    try {
      const reqBody: PayOrderBody = {
        ceremony_id: params.ceremonyid || '',
        payment_method: PaymentMethod.card,
        type: OrderType.ceremony_designer,
        product_id: plan.id,
        upsold_product_id: upsoldPlan?.id,
        value: purchasePrice,
        currency: params.selectedCurrency || 'USD',
        source: source,
        payment_type: 'one_time_payment',
        addons: selectedAddons,
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        await createPaymentSession(plan, orderResponse.data.ref);
      } else {
        openSnackBar(orderResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: 'me',
            payeeDetail: {
              email: '',
              name: '',
            },
            success: false,
          },
        });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (plan: PayOption, ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        pid: upsoldPlan?.id || plan.id,
        ref: ref,
        currency: params.selectedCurrency || 'USD',
      };
      const sessionResponse: PayOrderResponse = await newpaymentSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message, 5000, 'error');
        navigate(`/pay-status/${params.ceremonyid ?? ''}`, {
          state: {
            plan: plan,
            payee: 'me',
            payeeDetail: {
              email: '',
              name: '',
            },
            success: false,
          },
        });
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err as any), 5000, 'error');
    } finally {
      hideLoader();
    }
  };

  return (
    <div
      className={`bg-cosmicLatte-primary w-full text-forest-primary min-h-4/5 flex flex-col space-between items-center overflow-x-hidden absolute top-0 z-10 min-h-full`}
    >
      <CampaignItem
        regularComponent={<></>}
        renderCampaignComponent={(discountPercentage, couponCode) => (
          <div className='w-full px-1 md:px-2 py-1 md:py-2 text-center bg-copper-primary text-white mb-2 cursor-pointer underline-offset-4'>
            {`GET ${discountPercentage}% OFF | USE CODE: `}
            <u>{couponCode}</u>
            {` | OFFER ENDS SOON!`}
          </div>
        )}
      />
      <div className='flex flex-row my-2 lg:my-4 self-start justify-between cursor-pointer px-4 lg:px-11 w-full'>
        <div
          className='flex flex-row grow align-center items-center'
          onClick={() => navigate(-1)}
        >
          <img alt='Go to dashboard' width={32} src={arrowLeftSvg} />
          <div className='ml-2'>Back</div>
        </div>

        <div className='flex flex-row self-end'>
          <SelectField
            className='w-32 hidden'
            disabled={true}
            value={selectedCurrency}
            onChange={(value) => {
              setSelectedCurrency(value as string);
            }}
            options={CURRENCIES.map((currency) => {
              return {
                value: currency,
                label: currency,
              };
            })}
          />
        </div>
      </div>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <SnackbarComponent
        isActive={isActive}
        message={message}
        type={type}
        handleClose={handleClose}
      />

      <Title
        className='text-forest-primary font-recife font-normal'
        h={1}
        text='Select a Service'
      />

      <Title
        className='text-forest-primary font-recife font-normal'
        h={2}
        text='Price is a one-time fee per wedding'
      />

      {showCouponCodeBanner && (
        <div className='flex bg-neutral-300 p-3 rounded mb-3 mt-4 mx-4'>
          <InfoIcon className='flex align-center' />
          <div className='flex justify-between flex-wrap flex-1'>
            <Typography
              type='body-200'
              className='mr-2 my-auto'
              variant='functional-low'
            >
              For a limited time, access the Thank You Note Builder for FREE in
              each plan!
            </Typography>
          </div>
        </div>
      )}

      <Container className='w-full grid-flow-col content-between justify-center mt-4 auto-rows-fr lg:px-0'>
        <div className='self-center col-span-4 md:col-span-6 lg:col-span-12 w-full flex flex-row flex-wrap justify-center pb-6'>
          {payPlans
            .filter(
              (plan) =>
                parseFloat(getPlanPrice(plan.price, plan.id).toString()) >= 0
            )
            .map((plan: PayOption, index: number, array: PayOption[]) => {
              return (
                <div
                  className={`w-full md:w-1/2 lg:w-1/3 xl:w-1/3 max-w-[396px] min-w-[363px] px-2 mt-3 ${
                    frontendPlansData[
                      plan.name as keyof typeof frontendPlansData
                    ]?.hidden && ceremonyPlan?.product_id !== plan.id
                      ? 'hidden'
                      : ''
                  }`}
                  key={plan.id}
                >
                  <div
                    className={` 
                  ${
                    frontendPlansData[
                      plan.name as keyof typeof frontendPlansData
                    ]?.is_featured && ceremonyPlan?.product_id !== plan.id
                      ? 'border-copper-primary border-2'
                      : 'border-forest-primary border'
                  }
                  flex flex-col justify-between px-6 py-6 items-center h-full max-w-3xl mx-auto relative rounded-2xl`}
                  >
                    <div className='flex flex-col items-center w-full'>
                      <h5
                        className={`${
                          frontendPlansData[
                            plan.name as keyof typeof frontendPlansData
                          ]?.is_featured && ceremonyPlan?.product_id !== plan.id
                            ? 'bg-copper-primary text-white'
                            : 'hidden'
                        } font-grotesk text-sm absolute top-[-16px] px-3 py-1 rounded-md`}
                      >
                        Most Popular
                      </h5>
                      <div className='flex flex-row w-full'>
                        <div className='flex-auto'>
                          <h3 className='font-recife font-normal leading-8 w-full text-left text-2xl'>
                            {frontendPlansData[
                              plan.name as keyof typeof frontendPlansData
                            ]?.title || plan.name}
                          </h3>
                          <CampaignItem
                            regularComponent={
                              <h1 className='font-recife font-normal text-3xl text-left w-full'>
                                {`${
                                  getPlanPrice(plan.price, plan.id) === 0
                                    ? ''
                                    : `${
                                        CURRENCIES_SYMBOLS[
                                          selectedCurrency as keyof typeof CURRENCIES_SYMBOLS
                                        ]
                                      } ${getPlanPrice(plan.price, plan.id)}`
                                }`}
                              </h1>
                            }
                            renderCampaignComponent={(discountPercentage) => (
                              <>
                                <h1 className='inline font-recife font-normal text-3xl text-left w-full'>
                                  {`${
                                    getPlanPrice(plan.price, plan.id) === 0
                                      ? ''
                                      : `${
                                          CURRENCIES_SYMBOLS[
                                            selectedCurrency as keyof typeof CURRENCIES_SYMBOLS
                                          ]
                                        } ${(
                                          (parseFloat(
                                            getPlanPrice(
                                              plan.price,
                                              plan.id
                                            ).toString()
                                          ) *
                                            (100 - discountPercentage)) /
                                          100
                                        ).toFixed(2)}`
                                  }`}
                                </h1>
                                <h1 className='ml-2 inline font-recife font-normal text-xl text-left w-full line-through text-copper-primary'>
                                  {`${
                                    getPlanPrice(plan.price, plan.id) === 0
                                      ? ''
                                      : `${
                                          CURRENCIES_SYMBOLS[
                                            selectedCurrency as keyof typeof CURRENCIES_SYMBOLS
                                          ]
                                        } ${getPlanPrice(plan.price, plan.id)}`
                                  }`}
                                </h1>
                                <div className='flex flex-row justify-start items-center'>
                                  <div
                                    className={
                                      'bg-copper-primary text-white font-grotesk text-sm px-3 py-1 rounded-md'
                                    }
                                  >
                                    {`Save ${discountPercentage}%`}
                                  </div>
                                  <Typography className='ml-2'>
                                    Offer Ends Soon!
                                  </Typography>
                                </div>
                              </>
                            )}
                          />
                          <Typography
                            className='text-forest-300 text-sm font-recife'
                            variant='functional-low'
                          >
                            one-time fee
                          </Typography>
                          {ceremonyPlan?.product_id &&
                            ceremonyPlan?.product_id !== plan.id && (
                              <h1 className='font-recife text-copper-primary text-base'>
                                (upgrade price)
                              </h1>
                            )}
                        </div>
                        <div className='flex-auto text-right'>
                          <img
                            className='inline-block'
                            src={
                              frontendPlansData[
                                plan.name as keyof typeof frontendPlansData
                              ]?.icon ||
                              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg=='
                            }
                            alt='plan icon'
                          />
                        </div>
                      </div>
                      <div className='w-full flex flex-col pt-4'>
                        <p className='self-center font-recife text-sm text-forest-primary mb-4'>
                          {frontendPlansData[
                            plan.name as keyof typeof frontendPlansData
                          ]?.description || ''}
                        </p>
                        <div
                          className={`grid ${
                            array.length === 1
                              ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2'
                              : 'grid-cols-1'
                          } w-full max-w-xl self-center`}
                        >
                          {frontendPlansData[
                            plan.name as keyof typeof frontendPlansData
                          ]?.attributes.map((feature, index) => {
                            return (
                              <PayPlansList
                                key={index}
                                title={feature.title}
                                elevio={feature.elevio}
                                negative={feature.negative}
                                positive={feature.positive}
                              />
                            );
                          }) ||
                            plan.features.map((feature, index) => {
                              return (
                                <PayPlansList
                                  key={index}
                                  title={feature.title}
                                  subtitle={feature.subtitle}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col gap-2 w-full'>
                      <ButtonPill
                        onClick={() => {
                          handleAddToCart(plan);
                        }}
                        text={
                          ceremonyPlan
                            ? ceremonyPlan?.product_id === plan.id
                              ? 'Current Plan'
                              : 'Upgrade'
                            : 'Select'
                        }
                        disabled={
                          ceremonyPlan?.product_id === plan.id ||
                          getPlanPrice(plan.price, plan.id) === 0
                        }
                        className={`w-full mt-8 text-white ${
                          ceremonyPlan?.product_id === plan.id
                            ? 'bg-forest-disabled'
                            : 'bg-forest-primary'
                        }`}
                      />

                      {[
                        'Vows',
                        'The Modern Wedding',
                        'The Grand Wedding',
                      ].includes(plan.name) &&
                        plan.version >= 3 && (
                          <button
                            type='button'
                            onClick={() => {
                              plan.name === 'Vows' && setElevioArticleId(101);
                              plan.name === 'The Modern Wedding' &&
                                setElevioArticleId(102);
                              plan.name === 'The Grand Wedding' &&
                                setElevioArticleId(103);
                              setLearnModeOpen(true);
                            }}
                            className='text-forest-primary font-base underline underline-offset-4 decoration-dotted'
                          >
                            Learn more
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Container>

      <Container>
        <div
          className={`col-span-4 md:col-span-6 lg:col-span-12 w-full flex flex-row flex-wrap justify-center pb-6 mt-0 lg:mt-10`}
        >
          <div
            className={`max-w-[560px] font-recife text-xl font-light relative mt-10 md:mt-[40px] order-2 md:order-1`}
          >
            <img
              src={Quotes}
              alt='quotes'
              className='absolute top-[-30px] md:left-[-30px]'
            />
            Before using Provenance, we didn’t even know where to begin crafting
            our ceremony and vows. Your tools made it so easy to walk through
            ideas and thoughtfully design our ceremony and both sets of vows.
            <div className='font-grotesk font-[500] text-base mt-4'>
              Alex R | BRIDE
            </div>
          </div>
          <div className=' order-1 md:order-2'>
            <img
              src={TestimonialImg}
              width={240}
              alt='testimonial'
              className='ml-0 lg:ml-32 mt-4 lg:mt-0'
            />
          </div>
        </div>
      </Container>

      <Modal
        isOpen={learnMoreOpen}
        onClose={() => setLearnModeOpen(false)}
        fullWidth
        maxWidth='md'
        backgroundColor='#ffffff'
      >
        <div
          className='ElevioModal-wrapper'
          dangerouslySetInnerHTML={{
            __html: `<elevio-element data-type="article" data-id="${
              elevioArticleId !== 0 ? elevioArticleId : 40
            }"></elevio-element>`,
          }}
        />
      </Modal>

      <UpsellModal
        isUpsellModalOpen={isUpsellModalOpen}
        setUpsellModalOpen={setUpsellModalOpen}
        baseProduct={selectedPlan}
        handlePlaceOrder={handlePlaceOrder}
        handleSkip={setSkipUpsell}
        products={payPlans}
        setSelectedPlan={setSelectedPlan}
        setUpsoldPlan={setUpsoldPlan}
        upsoldPlan={upsoldPlan}
        addons={addons}
        selectedAddons={selectedAddons}
        setSelectedAddons={setSelectedAddons}
      />
    </div>
  );
};

export default PayPlans;

import React from 'react';
import ReactGA from 'react-ga4';

import TheProvenanceCenterLogo from '../../assets/images/image/the_provenance_center_logo.png';
import Button from '../../components/v2/Button/Button';
import { Modal } from '../../components/v2/Modal/Modal';
import { Typography } from '../../components/v2/Typography/Typography';
import { useDashboard } from '../../provider/dashboardProvider';

const OrdainedInfoModal = () => {
  const {
    ordainedInfoModalOpen,
    setOrdainedInfoModalOpen,
    setOrdainedApplicationModalOpen,
  } = useDashboard();

  const handleContinue = () => {
    ReactGA.event({
      category: 'Ordination',
      action: 'Ordination Started',
      label: 'Ordination Started',
    });

    setOrdainedInfoModalOpen(false);
    setOrdainedApplicationModalOpen(true);
  };

  return (
    <Modal
      isOpen={ordainedInfoModalOpen}
      onClose={() => setOrdainedInfoModalOpen(false)}
      showCloseButton={true}
      title={''}
    >
      <div className='max-w-[500px] flex flex-col justify-center items-center'>
        <img
          src={TheProvenanceCenterLogo}
          alt='The Provenance Center Logo'
          className='h-28 mt-2'
        />
        <Typography
          type='display-100'
          className='text-center max-w-[436px] mt-6'
        >
          Get legally ordained in 1 minute!
        </Typography>
        <Typography type='body-400' className='text-center mt-4 max-w-[436px]'>
          Once complete, you’ll be able to confidently officiate weddings across
          the United States.
        </Typography>
        <div className='border border-forest-primary rounded-3xl mt-8 p-6'>
          <Typography variant='functional-high' type='body-400'>
            Things to note before you begin
          </Typography>
          <ul className='pl-4 max-w-[436px]'>
            <li className='list-disc pt-4'>
              <Typography variant='functional-low' type='body-400'>
                Instant, free, online ordination made possible by the The Provenance
                Center
              </Typography>
            </li>
            <li className='list-disc pt-4'>
              <Typography variant='functional-low' type='body-400'>
                After finishing, you can order your official certificate and other resources.
              </Typography>
            </li>
          </ul>
        </div>
        <Button className='mt-8' onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default OrdainedInfoModal;
